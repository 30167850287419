import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import "../../assets/css/Form.css";
import "../../assets/css/Button.css";

function PriceInputField({
  value,
  onKeyDown,
  onChange,
  setPrice,
  setMailPaypal,
  onConfirm,
}) {
  const [inputValue, setInputValue] = useState(value || "");
  const [currency, setCurrency] = useState({ value: "1", label: "EUR" });
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validateForm();
  }, [inputValue, email]);

  const validateForm = () => {
    const minAmount = currency.label === "EUR" ? 0 : 0;
    const numericValue = parseFloat(inputValue.replace(",", "."));
    const isAmountValid = numericValue >= minAmount;
    const isEmailValid = validateEmail(email);
    setIsValid(isAmountValid && isEmailValid);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    const formattedValue = formatValue(newValue, currency.label);
    setInputValue(formattedValue);

    // Update the price with currency only (fee calculation commented out)
    // const feeIncludedPrice = calculateFee(formattedValue);
    setPrice({
      amount: formattedValue, // feeIncludedPrice
      currency: currency.label,
    });

    // Call the onChange prop if provided, converting back to a standard format
    if (onChange) {
      onChange(unformatValue(formattedValue));
    }
  };

  const handlePaypalEmailChange = (e) => {
    setEmail(e.target.value);
    setMailPaypal(e.target.value);
  };

  const formatValue = (value, currencyLabel) => {
    if (value === "") {
      return "";
    }

    let numberValue = value.replace(/,/g, ".").replace(/[^\d.]/g, "");

    const match = numberValue.match(/^-?\d*(\.\d{0,2})?/);
    numberValue = match ? match[0] : "";

    if (currencyLabel === "EUR") {
      numberValue = numberValue.replace(".", ",");
    }

    return numberValue;
  };

  const unformatValue = (value) => {
    return value.replace(/,/g, ".");
  };

  // Function to calculate fee (currently not in use)
  function calculateFee(initialPrice) {
    const feeRate = 0.04; // 4% Fee
    const numericPrice = parseFloat(initialPrice.replace(/,/g, "."));
    if (!isNaN(numericPrice)) {
      const priceWithFee = numericPrice + numericPrice * feeRate;
      return currency.label === "EUR"
        ? priceWithFee.toFixed(2).replace(".", ",")
        : priceWithFee.toFixed(2);
    }
    return "";
  }

  const handleRightClick = async (e) => {
    e.preventDefault();
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputValue(formatValue(clipboardText, currency.label));

      // Update price with the currency only (fee calculation commented out)
      // const feeIncludedPrice = calculateFee(clipboardText);
      setPrice({
        amount: formatValue(clipboardText, currency.label), // feeIncludedPrice
        currency: currency.label,
      });
      if (onChange) {
        onChange(unformatValue(clipboardText));
      }
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };

  return (
    <div className="input-field">
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormGroup>
            <Row className="price-input-container">
              <div className="price-input-wrapper">
                <Col>
                  <Input
                    className="form-control-button price-input-element"
                    type="text"
                    maxLength={7}
                    placeholder="0"
                    value={inputValue}
                    onChange={handleChange}
                    onContextMenu={handleRightClick}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col>
                  <div className="price-input-container">
                    <select
                      className="price-input-element-sm"
                      id="price-dropdown"
                      value={currency.value}
                      onChange={(e) => {
                        const selectedOption =
                          e.target.value === "1"
                            ? { value: "1", label: "EUR" }
                            : { value: "2", label: "USD" };
                        setCurrency(selectedOption);
                        setInputValue(formatValue(inputValue, selectedOption.label)); // Update input based on new currency
                        setPrice({
                          amount: formatValue(inputValue, selectedOption.label), // calculateFee(inputValue),
                          currency: selectedOption.label,
                        });
                      }}
                    >
                      <option value="1">EUR</option>
                      <option value="2">USD</option>
                    </select>
                  </div>
                </Col>
              </div>
              <div className="email-input-field">
                <Col>
                  <Input
                    className="form-control-button email-input-el"
                    type="email"
                    placeholder="Enter your PayPal email"
                    onChange={handlePaypalEmailChange}
                  />
                </Col>
              </div>
              <div className="set-price-text-container">
                <p className="set-price-text">Total</p> {/* incl. Fee */}
                <p className="set-price-text set-price-text-price">
                  {/* calculateFee(inputValue) */} {inputValue} {currency.label}
                </p>
              </div>
              <div className="set-price-button-container">
                <Button
                  id="confirm-price-button"
                  className=""
                  disabled={!isValid}
                  onClick={onConfirm}
                >
                  <div className="button-text-set-price">
                    <span>Confirm Price</span>
                    <span>+</span>
                    <span style={{ color: "var(--primary)" }}> Swap</span>
                  </div>
                </Button>
              </div>
              <div className="set-price-checkbox-container">
                <Input
                  type="checkbox"
                  disabled
                  className="checkbox-custom-set-price"
                />
                <p className="set-price-text-2">Multiple Swaps (Premium)</p>
              </div>
            </Row>
          </FormGroup>
        </Form.Group>
      </Form>
    </div>
  );
}


export default PriceInputField;
