import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import "../../assets/css/Homepage.css";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useAuth } from "layouts/AuthContext.js";
import NotificationAlert from "react-notification-alert";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { auth } from "routes/FireBaseSetup/firebase.tsx";
import Form from "react-bootstrap/Form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,

  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { LayoutTypes } from "model/LayoutTypes.tsx";
import {
  handleLogin,
  handleResetPassword,
  notify as notifyLogin,
} from "./Login.js";
import { handleRegister, notify as notifyRegister } from "./Register.js";

const RegisterLogin = () => {
  const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Register
  const [state, setState] = useState({});

  //TODO terms and conditions is mandatory
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const { setIsAuthenticated } = useAuth();
  const notificationAlertRef = useRef(null);
  const [alertCount, setAlertCount] = useState(0);
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);
  const [notifyGone, setNotifyGone] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggingIn, setisLoggingIn] = useState(false);
  const disallowedUsernames = ["hitler", "badword1", "badword2"]; // Add more as needed

  useEffect(() => {
    document.body.classList.toggle("auth-page");
    return () => {
      document.body.classList.toggle("auth-page");
    };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const customResetPassword = async () => {
    const result = await handleResetPassword(state.username, getFirestore());
    let emailToReset = state.username;

    switch (result) {
      case "RESET_EMAIL_SENT":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "successPasswordResetMail",
          userEmail: emailToReset,
        });
        break;
      case "EMAIL_MISSING":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailMissing",
        });
        break;
      case "EMAIL_NOT_FOUND":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailNotFound",
        });
        break;
      case "RESET_FAILED":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
      default:
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
    }
  };

  const customHandleLogin = async (e) => {
    setisLoggingIn(true)
    e.preventDefault();

    const result = await handleLogin({
      username: state.username,
      password: state.password,
      db: getFirestore(),
    });

    switch (result) {
      case "SUCCESS":
        setIsAuthenticated(true);
        setNotifyGone(true);
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "success",
          username: state.username,
        });
        break;
      case "USERNAME_NOT_FOUND":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorUserNameNotFound",
          username: state.username,
        });
        break;
      case "MISSING_FIELDS":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorInputMissing",
          username: state.username,
        });
        break;
      case "WRONG_PASSWORD":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorPasswordIncorrect",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      case "TOO_MANY_REQUESTS":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorTooManyRequests",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      default: // 'ERROR'
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
          username: state.username,
        });
        break;
    }
    setisLoggingIn(false)
  };

  const isValidUsername = (username) => {
    // Check length
    if (username.length < 5 || username.length > 10) return false;

    // Check for invalid characters
    const validChars = /^[a-zA-Z0-9]+$/;
    if (!validChars.test(username)) return false;

    // Check against disallowed usernames
    if (disallowedUsernames.includes(username.toLowerCase())) return false;

    return true;
};

  const customHandleRegister = async (e) => {
    setIsRegistering(true);
    e.preventDefault();

    // Validate the username
    if (!isValidUsername(state.username)) {
      notifyRegister(notificationAlertRef, {
            notifyPlace: "tc",
            notifyColor: 3,
            notifyMessage: "errorUserNamePolicy",
            username: state.username,
            callback: () => setIsRegistering(false)
        });
        return;
    }

    const result = await handleRegister({
        username: state.username,
        email: state.email,
        password: state.password,
        db: getFirestore(),
    });

    switch (result) {
        case "SUCCESS":
            setNotifyGone(true);
            notifyRegister(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 2,
                notifyMessage: "success",
                username: state.username,
            });
            break;
        case "USER_TAKEN":
          notifyRegister(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorUserNameTaken",
                username: state.username,
            });
            break;
        case "EMAIL_TAKEN":
          notifyRegister(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorMailTaken",
                username: state.username,
            });
            break;
        case "MISSING_FIELDS":
          notifyRegister(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorInputMissing",
                username: state.username,
            });
            break;
        default: // 'ERROR'
        notifyRegister(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "error",
                username: state.username,
            });
            break;
    }
    setIsRegistering(false);
};


  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      ((agreedToTerms && !isLogin) || (!agreedToTerms && isLogin))
    ) {
      e.preventDefault(); // Prevent the default action
      isLogin ? customHandleLogin(e) : customHandleRegister(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (notifyGone) {
      const timer = setTimeout(() => {
        navigate(`../${LayoutTypes.layout2}/swap/`);
      }, 1000);

      // Cleanup timeout if component unmounts to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [notifyGone, navigate]);

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="dashboard-background"> </div>
      <div className="position-top content ">
        <Container>
          <Row className="justify-content-center">
            <Col lg="15" md="60">
              <Form className="form" onKeyDown={handleKeyDown}>
                <Card className="reg-log-card">
                  <CardHeader className="text-center">
                    <CardTitle tag="h2" className="pt-2">
                      {isLogin ? "Log in" : "Register"}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {!isLogin && (
                      <>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.usernameFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="username"
                            placeholder="Username"
                            type="text"
                            onFocus={(e) =>
                              setState({ ...state, usernameFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, usernameFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.emailFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="text"
                            onFocus={(e) =>
                              setState({ ...state, emailFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, emailFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </>
                    )}
                    {isLogin && (
                      <InputGroup
                        className={
                          "mb-3 " +
                          classnames({ "input-group-focus": state.emailFocus })
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          placeholder="Username / Email"
                          type="text"
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    )}
                    <InputGroup
                      className={
                        "mb-3 " +
                        classnames({ "input-group-focus": state.passFocus })
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={handleInputChange}
                      />
                    </InputGroup>

                    {!isLogin && (
                      <FormGroup
                        check
                        className={`text-left ${
                          termsError ? "text-danger" : ""
                        }`}
                      >
                        <Label
                          className={`${termsError ? "text-danger" : ""}`}
                          check
                        >
                          <Input
                            type="checkbox"
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                          />
                          <span className="form-check-sign" />I agree to the
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            className={`${termsError ? "text-danger" : ""}`}
                          >
                            <span className="term-cond">
                              terms and conditions
                            </span>
                          </a>
                          .
                        </Label>
                      </FormGroup>
                    )}
                  </CardBody>
                  <CardFooter className="text-center">
                    {isLogin ? (
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={customHandleLogin}
                        size="lg"
                        disabled={isLoggingIn}
                      >
                        Log In
                      </Button>
                    ) : (
                      <Button
                        className="mb-3"
                        color="primary"
                        onClick={customHandleRegister}
                        disabled={!agreedToTerms || isRegistering}
                      >
                        Register
                      </Button>
                    )}

                    {isLogin ? (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(false)}
                        >
                          Create Account
                        </Button>

                        <h6>
                          <a
                            className="link footer-link"
                            onClick={() => customResetPassword(state.username)}
                          >
                            Reset Password
                          </a>
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(true)}
                        >
                          Already have an account?
                          <br></br>
                          Log in
                        </Button>
                      </div>
                    )}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RegisterLogin;
