import React, { useEffect, useRef, useState } from "react";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword as updateFirebasePassword,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import Form from "react-bootstrap/Form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

const Profile = () => {
  const [originalUsername, setOriginalUsername] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const notificationAlertRef = useRef(null);
  const [state, setState] = React.useState({ email: "", password: "" });
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);

  const [userEmail, setUserEmail] = useState(""); // to differentiate from input email in modal
  const [email, setEmail] = useState(""); // For reauthentication in modal
  const [oldPassword, setOldPassword] = useState(""); // For reauthentication
  const [newPassword, setNewPassword] = useState("");
  const [modal, setModal] = useState(false); // For displaying the password change modal
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email); // Correctly set user email from authentication state
        const fetchUserDetails = async () => {
          setLoading(true);
          const userRef = doc(db, "USERS", user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            setOriginalUsername(userData.user_username);
            setUsername(userData.user_username);
          } else {
            setOriginalUsername("Error: No user found");
          }
          setLoading(false);
        };
        fetchUserDetails();
      }
    });
  }, [auth, db]);

  const toggleModal = () => {
    setEmail(userEmail); // Reset email in modal to current user's email every time modal is toggled
    setModal(!modal);
  };

  const handleSave = async () => {
    // Check if trying to change password
    if (newPassword) {
      toggleModal(); // Show modal for reauthentication before password change
    } else {
      // Update username or other non-sensitive information
      updateProfileInfo();
    }
  };

  const updateProfileInfo = async () => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      const userRef = doc(db, "USERS", userToken);
      await updateDoc(userRef, { user_username: username });
      notify("tr", 2, "success");
    }
  };

  const handleChangePassword = async () => {
    if (email === userEmail) {
      // Check if input email matches current user's email
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(userEmail, oldPassword); // Use userEmail from auth state
      try {
        await reauthenticateWithCredential(user, credential);
        await updateFirebasePassword(user, newPassword);
        notify("tr", 2, "success");
        toggleModal(); // Close modal after successful update
        setOldPassword("");
        setNewPassword("");
      } catch (error) {
        notify("tr", 3, "PWError");
      }
    } else {
      notify("tr", 3, "MailError");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action to stop form submission
      handleSave(e);
    }
  };

  const handleKeyDown2 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action to stop form submission
      handleChangePassword(e);
    }
  };

  const notify = (
    notifyPlace,
    notifyColor,
    notifyMessage,
    callback,
    username
  ) => {
    if (isNotificationDisplayed) {
      return;
    }
    setIsNotificationDisplayed(true);

    const messages = {
      success: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Success</b>
            </h1>
          </div>
          <div>Profile updated successfully</div>
        </div>
      ),

      MailError: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>Email incorrect</div>
          <div>
            <b>Please try again</b>
          </div>
        </div>
      ),

      PWError: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>Password incorrect</div>
          <div>
            <b>Please try again</b>
          </div>
        </div>
      ),
    };

    let type;
    switch (notifyColor) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        type = "info"; // Default case if no color matches
        break;
    }

    const options = {
      place: notifyPlace,
      message: messages[notifyMessage], // Use the message from the 'messages' object or default to the passed message
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 1.5, // Adjusted for longer display time
    };

    // Display the notification
    notificationAlertRef.current.notificationAlert(options);

    // Increment and then auto-decrement alert count
    setState((prevState) => ({
      ...prevState,
      alertCount: prevState.alertCount + 1,
    }));
    setTimeout(() => {
      setIsNotificationDisplayed(false);
      setState((prevState) => ({
        ...prevState,
        alertCount: prevState.alertCount - 1,
      }));
      if (callback) callback(); // Call the callback function after the timeout
    }, 1800); // Match this with autoDismiss
  };

  const anonymizeEmail = (email) => {
    if (!email) return "";
    let parts = email.split("@");
    let localPart = parts[0];
    let anonymized = localPart[0] + "*".repeat(localPart.length - 1);
    return anonymized + "@" + parts[1];
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100svh",
        }}
      >
        Loading...
      </div>
    );
  } else {
    return (
      <>
        <NotificationAlert ref={notificationAlertRef} />
        
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form onKeyDown={handleKeyDown}>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Username</label>
                          <Input
                            type="text"
                            value={username || ""}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={true}
                            className="text-light" // Ensure text is visible
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            value={anonymizeEmail(userEmail)} // Display current user's email
                            type="email"
                            disabled={true}
                            className="text-light" // Ensure text is visible
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>New Password</label>
                          <Input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="text-light" // Ensure text is visible
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirm Identity</ModalHeader>
          <ModalBody>
            <Form onKeyDown={handleKeyDown2}>
              <FormGroup>
                <label>Email address</label>
                <Input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-dark" // Ensure text is visible
                />
              </FormGroup>
              <FormGroup>
                <label>Current Password</label>
                <Input
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="text-dark" // Ensure text is visible
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleChangePassword}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
};

export default Profile;
