import '../../assets/css/SwapCard.css'

interface SwapCardTitleProps {
    title1: string;
    title2: string;
  }
const SwapCardTitle = ({title1, title2}: SwapCardTitleProps) => {

return (
    <div className='swap-title'>
        <h1>{title1}</h1> 
        <h1 className='shifted-title'>{title2}</h1>
    </div>

);
}

export default SwapCardTitle;
