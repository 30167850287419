import React, { createContext, useContext } from 'react';
import { app, analytics, firestore, auth, FirebaseApp, Firestore, Auth } from './firebase';

interface FirebaseContextProps {
  app: FirebaseApp;
  analytics: any; // Replace 'any' with the correct type from the firebase/analytics package
  firestore: Firestore;
  auth: Auth;
}

const FirebaseContext = createContext<FirebaseContextProps | null>(null);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const firebase = { app, analytics, firestore, auth };

  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export const useFirebase = (): FirebaseContextProps => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
