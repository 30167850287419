import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import rightArrowImage from "../../../assets/css/right-arrow-icon.png";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/Button.css";
import NotificationAlert from "react-notification-alert";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { SwapState } from "model/SwapData";

interface Options {
  place: string;
  message: React.ReactNode;
  type: string;
  icon: string;
  autoDismiss: number;
}

interface ForwardButtonProps {
  onClick: () => Promise<string>;
  onKeyDown?: (value: string) => void;
  swapId?: string;
  children?: React.ReactNode;
  value?: string;
}

const ForwardButton: React.FC<ForwardButtonProps> = ({
  onClick,
  children,
  value,
}) => {
  const navigate = useNavigate();
  const notificationAlertRef = useRef<any>(null);
  const [alertCount, setAlertCount] = useState(0); // State to track the number of active alerts
  const firestore = getFirestore(); // Get Firestore instance directly here

  //notify -> refactor
  const notify = (place: string, notifyMessage: string) => {
    if (!notificationAlertRef.current || alertCount >= 1) {
      return;
    }
    if (alertCount >= 1) {
      // Prevent more than 1 alerts from being displayed at a time
      return;
    }

    const messages: any = {
      invalidCodeChar: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>please provide a 5-digit swap code</div>
        </div>
      ),
      noCode: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>please provide a swap code</div>
        </div>
      ),
      wrongCode: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>
            <b>Swap #{value}</b> could not be found
          </div>
          <div>please provide a valid swap code</div>
        </div>
      ),
      swapOccupied: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>
            <b>Swap #{value}</b> is occupied
          </div>
          <div>please create a new swap</div>
        </div>
      ),
    };

    let type = "danger";
    const options: Options = {
      place: place,
      message: messages[notifyMessage] || <div>{notifyMessage}</div>,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };

    // Increment alert count
    setAlertCount((currentCount) => currentCount + 1);
    notificationAlertRef.current.notificationAlert(options);

    // Auto-decrement alert count after dismissal (assuming autoDismiss is in seconds)
    setTimeout(() => {
      setAlertCount((currentCount) => currentCount - 1);
    }, options.autoDismiss * 1000);
  };

  const handleForwardClick = async () => {
    const swapCode = await onClick();
    if (swapCode && !value) {
      navigate(`../${swapCode}`);
    }
    if (value === "") {
      // If the value is empty, notify no code provided
      notify("tc", "noCode");
    } else if (value && value.length < 5) {
      // If a value is provided but is less than 5 characters, notify it's an invalid code due to character length
      notify("tc", "invalidCodeChar");
    } else if (value && swapCode) {
      const canNavigate = await checkIfSwapClosed(value);
      if (canNavigate) {
        navigate(`../${swapCode}`);
      }
    } else {
      // If none of the above conditions are true, then notify wrong code
      notify("tc", "wrongCode");
    }
  };

  const checkIfSwapClosed = async (value: string) => {
    const swapRef = doc(firestore, "SWAPS", `SWAP-${value}`); // Adjust this if necessary to match your document path
    const swapDoc = await getDoc(swapRef);

    if (swapDoc.exists()) {
      const swapData = swapDoc.data();

      if (
        swapData.IS_SWAP_CLOSED ||
        swapData.SWAP_USER_B_INFO.SWAP_STATE == SwapState.UPLOADED ||
        swapData.SWAP_USER_B_INFO.SWAP_STATE == SwapState.CONFIRMED_SWAP
      ) {
        notify("tc", "swapOccupied");
        return false;
      } else {
        return true;
      }
    } else {
      notify("tc", "wrongCode");
      return false;
    }
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <Button
        id="forward-button-one"
        onClick={handleForwardClick}
        variant="info"
        className="button-temp forward-button"
      >
        {children}
      </Button>
    </>
  );
};

export default ForwardButton;
