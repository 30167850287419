import React, { useEffect, useState, useRef } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  Timestamp
} from "firebase/firestore";
import { Button, Form, FormGroup, Input, Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import "../../assets/css/Homepage.css";
import "../../assets/css/Form.css";

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please enter a valid email.");
      return;
    }

    const db = getFirestore();
    const userLeadsCollection = collection(db, "LEADS_MAIL");

    try {
      const q = query(userLeadsCollection, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMessage("Email is already registered.");
        return;
      }

      await addDoc(userLeadsCollection, {
        email,
        datetime: Timestamp.fromDate(new Date())
      });
      setMessage("Email submitted successfully!");
      setEmail("");
      showNotification();
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("Error submitting email. Please try again.");
    }
  };

  const showNotification = () => {
    const options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b>SWAPPD.IO</b>
          </div>
          <div>
            Email saved successfully! <br /> You will be updated!
          </div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="dashboard-background-picture z0"></div>
      <div className="position-top content form-center">
        <Container>
          <Row>
            <Col md="6" className="ml-auto mr-auto">
              <Card>
                <CardHeader>
                    <br></br>
                  <h5 className="title" style={{ textAlign: "center" }}>
                    Premium Features launching soon! <br /> Don't miss it!
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <Button color="primary" type="submit" block>
                      Submit
                    </Button>
                  </Form>
                  {message && <div className="mt-3">{message}</div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmailForm;
