import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/Form.css";
import "../../assets/css/Button.css";
import "../../assets/css/SwapCard.css";
import "../../assets/css/Timeline.css";
import NotificationAlert from "react-notification-alert";
import InputCard from "../InputCard/InputCard";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { firestore } from "routes/FireBaseSetup/firebase.tsx";
import { SwapItemState, SwapMethod, SwapState } from "model/SwapData.tsx";
import { updateAndUploadSwapData } from "../../utils/updateAndUploadSwapData.js";
import axios from 'axios';

function FileInput({
  onUploadComplete,
  id,
  userRole,
  swapData,
  setSwapData,
  onPayPalSelected,
}) {
  const [files, setFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadDots, setUploadDots] = useState("");
  const [alert, setAlert] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isFileInput, setIsFileInput] = useState(false);
  const [isTextInput, setIsTextInput] = useState(false);
  const [isPaypalInput, setIsPaypalInput] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const notificationAlertRef = useRef(null);
  const fileInputRef = useRef(null);
  const [alertCount, setAlertCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [price, setPrice] = useState(""); // Add state for price
  const [isPaypalConfirmed, setIsPaypalConfirmed] = useState(false);
  const [isPriceConfirmed, setIsPriceConfirmed] = useState(false);

  const [isSdkReady, setIsSdkReady] = useState(false);
  
  const addBtnColor = "primary";
  const addBtnClasses = "button-select-file";

  useEffect(() => {
    let dotCount = 0;
    const interval = isUploading
      ? setInterval(() => {
          dotCount = (dotCount + 1) % 4;
          setUploadDots(".".repeat(dotCount));
        }, 500)
      : null;

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isUploading]);

  useEffect(() => {
    setIsFileInput(selectedOption === "File");
    setIsTextInput(selectedOption === "Text");
    setIsPaypalInput(selectedOption === "Paypal");
  }, [selectedOption]);

  const handleSelect = () => {
    setShowPopup(true);
  };

  const handleOptionClick = async (option) => {
    const userInfoField = userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO";

    setSelectedOption(option);
    setShowPopup(false);
    setIsPaypalInput(true);

    if (option === "File") {
      const swapId = swapData.SWAP_ID;
      const updates = {
        additionalFields: {
          [userInfoField]: {
            SWAP_METHOD: SwapMethod.FILE,
          },
        },
      };
      try {
        await updateAndUploadSwapData(swapId, updates);
      } catch {
     /*    console.log("error on handleOptionClick");
      */ }
    }

    if (option === "Paypal") {
      const swapId = swapData.SWAP_ID;
      const updates = {
        additionalFields: {
          SWAP_USER_B_INFO: {
            SWAP_METHOD: SwapMethod.PAYMENT,
            SWAP_STATE: SwapState.PAYMENT_PRICE_PENDING,
          },
        },
      };

      updateAndUploadSwapData(swapId, updates);

      onPayPalSelected(true);
    }
  };

  const fetchLatestSwapData = async (swapData) => {
    if (swapData && swapData.SWAP_ID) {
      const db = getFirestore();
      const docRef = doc(db, "SWAPS", swapData.SWAP_ID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const latestSwapData = docSnap.data();
        swapData = latestSwapData;
        return swapData;
      }
    }
  };

  const uploadStateHandler = () => {
    const selectedFiles = fileInputRef.current?.files;
    if (selectedFiles) {
      setFiles(selectedFiles);
      setIsFileUploaded(false);
      setIsUploading(false);
    }
  };

  const modifyButtonHandler = () => {
    fileInputRef.current.click();
    setFiles(null);
    setIsFileUploaded(false);
    setIsUploading(false);
  };

  const notify = (place, notifyMessage, alertType) => {
    if (!notificationAlertRef.current) {
      return;
    }

    const messages = {
      price_set: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Success</b>
            </h1>
            <div>Price was set successfully</div>
          </div>
        </div>
      ),
      invalid_price: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
            <div>Price could not be set. Please try Again.</div>
          </div>
        </div>
      ),
      payment_success: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Success</b>
            </h1>
          </div>
        </div>
      ),
      success: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Success</b>
            </h1>
          </div>
          <div>
            Your file was <b>successfully</b> uploaded
          </div>
        </div>
      ),
      error: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>Please try again</div>
        </div>
      ),
      fileTooBig: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>
            The file size exceeds the limit of your plan: <b> 10MB</b>
          </div>
          <div>Please upgrade your plan to increase the limit</div>
        </div>
      ),
      fileNameTooLong: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>
            Your file name is <b>way too long</b>
          </div>
          <div>File name must be 50 characters or less</div>
        </div>
      ),
      fileNotAllowed: (
        <div className="alert-customized">
          <div>
            <b>SWAPPD.IO</b>
            <h1>
              <b>Error</b>
            </h1>
          </div>
          <div>
            The file-type you tried to upload is <b>not included</b> in your plan
          </div>
          <div>Please upgrade your plan to update such files</div>
        </div>
      ),
    };

    let type = alertType;
    const options = {
      place: place,
      message: messages[notifyMessage] || <div>{notifyMessage}</div>,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };

    setAlertCount((currentCount) => currentCount + 1);
    notificationAlertRef.current.notificationAlert(options);

    setTimeout(() => {
      setAlertCount((currentCount) => currentCount - 1);
    }, options.autoDismiss * 1000);
  };

  const uploadSwapData = async (swapData, firestore) => {
    const swapId = swapData.SWAP_ID;
    await setDoc(doc(firestore, "SWAPS", swapId), swapData);
  };

  const handleUploadConfirmation = () => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 10 * 1024 * 1024) {
        notify("tc", "fileTooBig", "danger");
        return;
      }
      if (file.name.length > 50) {
        notify("tc", "fileNameTooLong", "danger");
        setFiles(null);
        return;
      }

      setAlert(
        <ReactBSAlert
          id="toast-alert-id"
          customClass="toast-alert-temp"
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => confirmUpload()}
          onCancel={() => denyUpload()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, upload it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          Are you sure you want to upload this file?
        </ReactBSAlert>
      );
    }
  };

  const handleSelectFile = () => {
    fileInputRef.current.click();
  };

  const handleReturn = () => {
    setSelectedOption(false);
    setIsFileInput(false);
    setIsTextInput(false);
    setIsPaypalInput(false);
    setShowPopup(false);
    setIsFileUploaded(false);
    setUploadDots(false);
    setIsUploading(false);
    setShowToast(false);
    setFiles(null);
  };

  const confirmUpload = async () => {
    if (!files || files.length === 0) {
      notify("tc", "No file selected", "danger");
      hideAlert();
      setIsFileUploaded(false);
      setShowToast(false);
      handleSelectFile();
      return;
    }
  
    hideAlert();
    setIsUploading(true);
    setShowToast(false);
  
    try {
      const file = files[0];
  
      const userInfoField = userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO";
      if (swapData[userInfoField]?.SWAP_METHOD !== SwapMethod.FILE) {
        const swapId = swapData.SWAP_ID;
        const updates = {
          additionalFields: {
            [userInfoField]: {
              SWAP_METHOD: SwapMethod.FILE,
            },
          },
        };
        try {
          await updateAndUploadSwapData(swapId, updates);
          const updatedSwapData = await fetchLatestSwapData({ SWAP_ID: swapId });
          setSwapData(updatedSwapData);
        } catch (error) {
          console.error("Error updating swapmethod to file", error);
        }
      }
  
      uploadSwapData(swapData, firestore);
      await onUploadComplete(file, id);
      setIsFileUploaded(true);
      
      // Call the server endpoint to check for viruses
      const formData = new FormData();
      formData.append('file', file);
  
/*       console.log('Sending file to server:', file); // Log the file being sent
      console.log('Sending file to servereee:', process.env.REACT_APP_API_BASE_URL); // Log the file being sent
   */
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/check_for_virus`, formData, {
        
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const isClean = response.data.status === SwapItemState.SAFE ? SwapItemState.SAFE : response.data.status === SwapItemState.NOT_SAFE ?
      SwapItemState.NOT_SAFE : SwapItemState.ERROR;
      await updateVirusScanResult(swapData, isClean);
    } catch (error) {
      notify("tc", "Upload failed", "danger");
      console.error("Upload error: ", error);
      setIsFileUploaded(false);
    } finally {
      setIsUploading(false);
    }
  };
  
  

  const updateVirusScanResult = async (swapData, isClean) => {
    const swapId = swapData.SWAP_ID;
    const swapInfoKey = userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO";
    const virusUpdate = {
      additionalFields: {
        [swapInfoKey]: {
          SWAP_ITEM_INFO: { SWAP_ITEM_STATUS: isClean },
        },
      },
    };

    updateAndUploadSwapData(swapId, virusUpdate);
  };

  const denyUpload = () => {
    setShowToast(false);
    setFiles(null);
    setIsUploading(false);
    hideAlert();
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      <div className="file-input-wrapper">
        <InputCard files={files} />
        <div className="file-input">
          {!selectedOption && (
            <Button
              color="primary"
              className="button-select-file"
              onClick={handleSelect}
            >
              Choose Option
            </Button>
          )}
          {isFileInput && !files && (
            <Button
              color={addBtnColor}
              className={addBtnClasses}
              onClick={() => handleSelectFile()}
            >
              Select File
            </Button>
          )}

          {selectedOption === "Text" && (
            <Button color={addBtnColor} className={addBtnClasses}>
              Enter Text
            </Button>
          )}

          {files && !isUploading && !isFileUploaded && (
            <div className="button-temp button-container">
              <Button
                id="button-modify"
                className="btn-fill"
                variant="warning"
                onClick={modifyButtonHandler}
              >
                Change
              </Button>
              <Button
                id="button-upload"
                className="btn-fill"
                color="info"
                onClick={handleUploadConfirmation}
              >
                Upload
              </Button>
            </div>
          )}

          {isUploading && (
            <Button className={addBtnClasses} variant="success" disabled>
              Uploading{uploadDots}
            </Button>
          )}

          {isFileUploaded && !isUploading && (
            <Button className={addBtnClasses} variant="success" disabled>
              Uploaded
            </Button>
          )}

          {(isFileInput || isTextInput || isPaypalInput) &&
            isUploading == false &&
            isFileUploaded == false && (
              <Button
                color="info"
                className="return-button"
                onClick={() => handleReturn()}
              >
                Return
              </Button>
            )}
        </div>
        {showToast && (
          <Button
            className="button-upload btn-fill "
            color="info"
            onClick={handleUploadConfirmation}
          >
            Upload File
          </Button>
        )}

        {alert}
      </div>
      {/* {accept=".jpg, .jpeg, .png, .mp3, .pdf, .wav"} */}
      <input
        type="file"
        accept="*/*"
        ref={fileInputRef}
        onChange={uploadStateHandler}
        style={{ display: "none" }}
      />

      {showPopup && (
        <ReactBSAlert
          customClass="popup-alert swap-select-option"
          style={{ display: "block", marginTop: "-100px" }}
          title="Select Method"
          onCancel={() => setShowPopup(false)}
          onConfirm={() => setShowPopup(false)}
          showCancel={false}
        >
          <div className="swap-options-menu">
            <Button
              className="button-transparent select-button"
              onClick={() => handleOptionClick("File")}
            >
              File
            </Button>
            {userRole === "userA" && (
              <Button
                disabled
                className="button-transparent select-button"
                onClick={() => handleOptionClick("Paypal")}
              >
                PayPal
              </Button>
            )}
            {userRole === "userB" && (
              <Button
                className="button-transparent select-button"
                onClick={() => handleOptionClick("Paypal")}
              >
                PayPal
              </Button>
            )}
          </div>
        </ReactBSAlert>
      )}
      <NotificationAlert ref={notificationAlertRef} />
    </>
  );
}

export default FileInput;