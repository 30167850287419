import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { LayoutTypes } from "model/LayoutTypes.tsx";
import { auth } from "routes/FireBaseSetup/firebase.tsx";
import { useAuth } from "layouts/AuthContext.js"; // Ensure this path is correct
import "../../assets/css/Homepage.css";
import { handleRegister, notify as notifyRegister } from "./Register.js";
import Form from "react-bootstrap/Form";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

export const notify = (
  notificationRef,
  { notifyPlace, notifyColor, notifyMessage, callback, username, userEmail }
) => {
  if (!notificationRef.current) return;

  const messages = {
    success: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Login</b>
          </h1>
        </div>
        <div>
          Welcome back, <b>{username}</b>!
        </div>
      </div>
    ),
    successPasswordResetMail: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Password Reset</b>
          </h1>
        </div>
        <div>
          Reset mail sent to <b>{userEmail}</b>
        </div>
        <div>Check your inbox!</div>
      </div>
    ),
    error: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Login Error</div>
        <div>
          <b>Please try again</b>
        </div>
      </div>
    ),
    errorUserNameNotFound: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Login Error</div>
        <div>
          <b>Username not found</b>
        </div>
      </div>
    ),
    errorEmailNotFound: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Password Reset</div>
        <div>
          <b>Email not found</b>
        </div>
      </div>
    ),
    errorInputMissing: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Login Error</div>
        <div>
          <b>Please fill out all fields</b>
        </div>
      </div>
    ),
    errorEmailMissing: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Password Reset</div>
        <div>
          <b>Please provide your email adress</b>
        </div>
      </div>
    ),
    errorPasswordIncorrect: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Login Error</div>
        <div>
          <b>Password incorrect</b>
        </div>
      </div>
    ),
    errorTooManyRequests: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Login Error</div>
        <div>
          <b>
            Access to this account has been temporarily disabled due to many
            failed login attempts. You can immediately restore it by resetting
            your password or you can try again later.
          </b>
        </div>
      </div>
    ),
  };

  let type;
  switch (notifyColor) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      type = "info"; // Default case if no color matches
      break;
  }

  const options = {
    place: notifyPlace,
    message: messages[notifyMessage], // Use the message from the 'messages' object or default to the passed message
    type: type,
    icon: "tim-icons icon-bell-55",
    autoDismiss: 1, // Adjust as needed
  };

  notificationRef.current.notificationAlert(options);
  if (callback) callback(); // Call the callback function after the notification
};

export const handleLogin = async ({ username, password, db }) => {
  if (!username || !password) {
    return "MISSING_FIELDS";
  }

  let userEmail;
  const usersRef = collection(db, "USERS");
  if (username.includes("@")) {
    userEmail = username; // Directly use the email if provided
  } else {
    const q = query(usersRef, where("user_username", "==", username));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return "USERNAME_NOT_FOUND";
    }
    userEmail = querySnapshot.docs[0].data().user_email;
  }

  try {
    await signInWithEmailAndPassword(auth, userEmail, password);
    return "SUCCESS";
  } catch (error) {
    console.error("Error logging in:", error);
    if (
      error.code === "auth/wrong-password" ||
      error.code === "auth/invalid-credential"
    ) {
      return "WRONG_PASSWORD";
    }
    if (error.code === "auth/too-many-requests") {
      return "TOO_MANY_REQUESTS";
    } else {
      return "OTHER_ERROR"; // Use this for all other types of errors
    }
  }
};

export const handleResetPassword = async (userInput, db) => {
  if (!userInput || userInput.length <= 0)  return "EMAIL_MISSING";
  let userEmail;
  if (userInput.includes("@")) {
    userEmail = userInput; // Directly use the email if provided
  } else {
    return "EMAIL_MISSING";
  }
  if (!userEmail) {
    return "EMAIL_MISSING";
  }

  const usersRef = collection(db, "USERS");
  const q = query(usersRef, where("user_email", "==", userEmail));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    // No user found with that email
    return "EMAIL_NOT_FOUND";
  }

  try {
    await sendPasswordResetEmail(auth, userEmail);
    return "RESET_EMAIL_SENT";
  } catch (error) {
    console.error("Error sending reset password email:", error);
    if (error.code === "auth/user-not-found") {
      return "EMAIL_NOT_FOUND";
    } else {
      return "RESET_FAILED";
    }
  }
};

const Login = () => {
  const [state, setState] = useState({ email: "", password: "", username: "" });
  const { setIsAuthenticated } = useAuth();
  const notificationAlertRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);
  const [notifyGone, setNotifyGone] = useState(false);
  const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Register
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggingIn, setisLoggingIn] = useState(false);

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const customResetPassword = async () => {
    const result = await handleResetPassword(state.username, getFirestore());
    let emailToReset = state.username;

    switch (result) {
      case "RESET_EMAIL_SENT":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "successPasswordResetMail",
          userEmail: emailToReset,
        });
        break;
      case "EMAIL_MISSING":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailMissing",
        });
        break;
      case "EMAIL_NOT_FOUND":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailNotFound",
        });
        break;
      case "RESET_FAILED":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
      default:
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
    }
  };

  const customHandleLogin = async (e) => {
    setisLoggingIn(true)
    e.preventDefault();

    const result = await handleLogin({
      username: state.username,
      password: state.password,
      db: getFirestore(),
    });

    switch (result) {
      case "SUCCESS":
        setIsAuthenticated(true);
        setNotifyGone(true); // Set notifyGone to true here
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "success",
          username: state.username,
        });
        break;
      case "USERNAME_NOT_FOUND":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorUserNameNotFound",
          username: state.username,
        });
        break;
      case "MISSING_FIELDS":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorInputMissing",
          username: state.username,
        });
        break;
      case "WRONG_PASSWORD":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorPasswordIncorrect",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      case "TOO_MANY_REQUESTS":
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorTooManyRequests",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      default: // 'ERROR'
        notify(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
          username: state.username,
        });
        break;
    }
    setisLoggingIn(false)
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      ((agreedToTerms && !isLogin) || (!agreedToTerms && isLogin))
    ) {
      e.preventDefault(); // Prevent the default action
      isLogin ? customHandleLogin(e) : customHandleRegister(e);
    }
  };

  const customHandleRegister = async (e) => {
    e.preventDefault();

    const result = await handleRegister({
      username: state.username,
      email: state.email,
      password: state.password,
      db: getFirestore(),
    });

    switch (result) {
      case "SUCCESS":
        setNotifyGone(true);
        notifyRegister(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "success",
          username: state.username,
        });
        break;
      case "USER_TAKEN":
        notifyRegister(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorUserNameTaken",
          username: state.username,
        });
        break;
      case "EMAIL_TAKEN":
        notifyRegister(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorMailTaken",
          username: state.username,
        });
        break;
      case "MISSING_FIELDS":
        notifyRegister(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorInputMissing",
          username: state.username,
        });
        break;
      default: // 'ERROR'
        notifyRegister(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
          username: state.username,
        });
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (notifyGone) {
      const timer = setTimeout(() => {
        navigate(`../${LayoutTypes.layout2}/swap/`);
      }, 1000);

      // Cleanup timeout if component unmounts to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [notifyGone, navigate]);

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="dashboard-background"> </div>
      <div className="position-top content ">
        <Container>
          <Row className="justify-content-center">
            <Col lg="15" md="60">
              <Form className="form" onKeyDown={handleKeyDown}>
                <Card className="reg-log-card">
                  <CardHeader className="text-center">
                    <CardTitle tag="h2" className="pt-2">
                      {isLogin ? "Log in" : "Register"}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {!isLogin && (
                      <>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.usernameFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="username"
                            placeholder="Username"
                            type="text"
                            onFocus={(e) =>
                              setState({ ...state, usernameFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, usernameFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.emailFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="text"
                            onFocus={(e) =>
                              setState({ ...state, emailFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, emailFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </>
                    )}
                    {isLogin && (
                      <InputGroup
                        className={
                          "mb-3 " +
                          classnames({ "input-group-focus": state.emailFocus })
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          placeholder="Username / Email"
                          type="text"
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    )}
                    <InputGroup
                      className={
                        "mb-3 " +
                        classnames({ "input-group-focus": state.passFocus })
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={handleInputChange}
                      />
                    </InputGroup>

                    {!isLogin && (
                      <FormGroup
                        check
                        className={`text-left ${
                          termsError ? "text-danger" : ""
                        }`}
                      >
                        <Label
                          className={`${termsError ? "text-danger" : ""}`}
                          check
                        >
                          <Input
                            type="checkbox"
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                          />
                          <span className="form-check-sign" />I agree to the
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            className={`${termsError ? "text-danger" : ""}`}
                          >
                            <span className="term-cond">
                              terms and conditions
                            </span>
                          </a>
                          .
                        </Label>
                      </FormGroup>
                    )}
                  </CardBody>
                  <CardFooter className="text-center">
                    {isLogin ? (
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={customHandleLogin}
                        size="lg"
                        disabled={isLoggingIn}
                      
                      >
                        Log In
                      </Button>
                    ) : (
                      <Button
                        className="mb-3"
                        color="primary"
                        onClick={customHandleRegister}
                        disabled={!agreedToTerms || isRegistering}
                      >
                        Register
                      </Button>
                    )}

                    {isLogin ? (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(false)}
                        >
                          Create Account
                        </Button>

                        <h6>
                          <a
                            className="link footer-link"
                            onClick={() => customResetPassword(state.username)}
                          >
                            Reset Password
                          </a>
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(true)}
                        >
                          Already have an account?
                          <br></br>
                          Log in
                        </Button>
                      </div>
                    )}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
