import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Homepage from "./Homepage";
import { useFirebase } from "../../routes/FireBaseSetup/fireBaseSetup";
import {
  collection,
  setDoc,
  doc,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import SwapPage from "./Swappage";
import {
  SwapData,
  SwapItemState,
  SwapMethod,
  SwapState,
} from "../../model/SwapData";
import useUpdateUserDetailsInSwap from "utils/getUserInfo";

const Mainpage: React.FC = () => {
  const location = useLocation();

  const { firestore } = useFirebase();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [swapData, setSwapData] = useState<SwapData | null>(null);
  const [userRole, setUserRole] = useState<"userA" | "userB">("userA");
  const [usernameA, setUserNameA] = useState<string>("");
  const [usernameB, setUserNameB] = useState<string>("");

  const [userUidA, setUserUidA] = useState<string>("");
  const [userUidB, setUserUidB] = useState<string>("");
  const updateUserDetailsInSwap = useUpdateUserDetailsInSwap();

  const setViewportUnits = () => {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };

  useEffect(() => {
    setViewportUnits();
    window.addEventListener('resize', setViewportUnits);
    return () => window.removeEventListener('resize', setViewportUnits);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (fileUrl && swapData) {
      updateSwapData();
    }
  }, [fileUrl, userRole]);

  const updateUserRole = (role: "userA" | "userB") => {
   /*  console.log("Setting user role in Mainpage:", role);
    */ setUserRole(role);
  };

  const generateRandomId = (): string => {
    const min = 10000;
    const max = 99999;
    return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
  };

  const handleJoinTradeClick = async (swapCode: string): Promise<string> => {
   /*  console.log("handleJoinTrade clicked with code:", swapCode);
 */
    const db = getFirestore();

    const swapsCollection = collection(db, "SWAPS");
    const q = query(swapsCollection, where("SWAP_CODE", "==", swapCode));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const swapDoc = querySnapshot.docs[0];
      const foundSwapData: SwapData = swapDoc.data() as SwapData;

      if (
        (!foundSwapData.SWAP_USER_A_INFO.SWAP_USER_NAME &&
          !foundSwapData.SWAP_USER_B_INFO.SWAP_USER_NAME) ||
        foundSwapData.IS_SWAP_CLOSED ||
        foundSwapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.UPLOADED ||
        foundSwapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP
      ) {
       /*  console.log(
          "Cannot join, swap is closed or user B has already taken action."
        ); */
        return "0";
      }

      const ipAddress = await fetchUserIpAddress();

   /*    console.log("Found swap data", foundSwapData);
 */
      const userToken = localStorage.getItem("userToken");
      let user_uid = "userB";
      let user_username = "userB";
      setUserNameB("userB");

      if (userToken) {
        const usersRef = collection(db, "USERS");
        const q = query(usersRef, where("user_uid", "==", userToken));
        const userSnapshot = await getDocs(q);
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          user_uid = userData.user_uid;
          user_username = userData.user_username;
          setUserNameB(user_username);
          setUserUidB(user_uid);
        }
      }
      let SwapMethodAftercheck = SwapMethod.NONE;
      if (foundSwapData.SWAP_PAYPAL.SWAP_PRICE_SET === true) {
        SwapMethodAftercheck = SwapMethod.PAYMENT;
      } else {
        SwapMethodAftercheck = SwapMethod.NONE;
      }

      const updatedSwapData = {
        ...foundSwapData,
        SWAP_USER_B_INFO: {
          ...foundSwapData.SWAP_USER_B_INFO,
          SWAP_IP: ipAddress,
          SWAP_USER: user_uid,
          SWAP_USER_NAME: user_username,
          SWAP_STATE: SwapState.JOINED,
          SWAP_METHOD: SwapMethodAftercheck,
        },
      };
    /*   console.log(updatedSwapData, "swpadataas updated ")
 */
      await setDoc(doc(db, "SWAPS", swapDoc.id), updatedSwapData);

      setSwapData(updatedSwapData);
      updateUserRole("userB");
     /*  console.log("UserRole after join:", userRole);
 */
      return foundSwapData.SWAP_CODE;
    } else {
     /*  console.log("No swap found with the given code.");
       */return "";
    }
  };

  const initSwapData = async (): Promise<[string, SwapData]> => {
    const userToken = localStorage.getItem("userToken");
    let user_uid = "userA";
    let user_username = "userA";
    setUserNameA(user_username);

    if (userToken) {
      const usersRef = collection(firestore, "USERS");
      const q = query(usersRef, where("user_uid", "==", userToken));
      const userSnapshot = await getDocs(q);
      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        user_uid = userData.user_uid;
        user_username = userData.user_username;
        setUserNameA(user_username);
        setUserUidA(user_uid);
      }
    }

    const generatedSwapCode = generateRandomId();
    const swapId = `SWAP-${generatedSwapCode}`;
    const currentTimestamp = new Date().toISOString();

    const ipAddress = await fetchUserIpAddress();

    const newSwapData = {
      SWAP_ID: swapId,
      SWAP_PAYPAL: {
        SWAP_PRICE_SET: false,
        SWAP_AMOUNT: "",
        SWAP_CURRENCY: "",
        SWAP_PAYEE: "",
        SWAP_PAYER: "",
      },
      SWAP_CODE: generatedSwapCode,
      SWAP_DATE: currentTimestamp,
      IS_SWAP_CLOSED: false,
      SWAP_USER_A_INFO: {
        SWAP_USER: user_uid,
        SWAP_USER_NAME: user_username,
        SWAP_IP: ipAddress,
        SWAP_STATE: SwapState.JOINED,
        SWAP_METHOD: SwapMethod.FILE,
        SWAP_ITEM_INFO: {
          SWAP_ITEM_LINK: "",
          SWAP_ITEM_NAME: "",
          SWAP_ITEM_STATUS: SwapItemState.NOT_CHECKED,
        },
      },
      SWAP_USER_B_INFO: {
        SWAP_USER: "",
        SWAP_USER_NAME: "",
        SWAP_IP: "",
        SWAP_STATE: SwapState.NOT_JOINED,
        SWAP_METHOD: SwapMethod.NONE,
        SWAP_ITEM_INFO: {
          SWAP_ITEM_LINK: "",
          SWAP_ITEM_NAME: "",
          SWAP_ITEM_STATUS: SwapItemState.NOT_CHECKED,
        },
      },
    };

    setSwapData(newSwapData);

    return [generatedSwapCode, newSwapData];
  };

  const updateSwapData = async (
    fileUrl?: string,
    file?: File
  ): Promise<void> => {
    if (!swapData) return;

    const newSwapState = fileUrl ? SwapState.UPLOADED : SwapState.JOINED;

    const updateFields = {
      SWAP_STATE: newSwapState,
      ...((fileUrl || file) && {
        SWAP_ITEM_INFO: {
          ...swapData[
            userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO"
          ].SWAP_ITEM_INFO,
          ...(fileUrl && { SWAP_ITEM_LINK: fileUrl }),
          ...(file && { SWAP_ITEM_NAME: file.name }),
        },
      }),
    };

    const updatedSwapData = {
      ...swapData,
      [userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO"]: {
        ...swapData[
          userRole === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO"
        ],
        ...updateFields,
      },
    };

    await setDoc(doc(firestore, "SWAPS", swapData.SWAP_ID), updatedSwapData);
    setSwapData(updatedSwapData);

  /*   console.log("Updated swap data:", updatedSwapData);
   */  setFileUrl("");
  };

  const handleCreateTradeClick = async (): Promise<string> => {
    const [swapCode] = await initSwapData();

    setUserRole("userA");
    return swapCode;
  };

  const fetchUserIpAddress = async (): Promise<string> => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "error";
    }
  };

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Homepage
            swapData={swapData}
            handleJoinTradeClick={handleJoinTradeClick}
            handleCreateTradeClick={handleCreateTradeClick}
          />
        }
      />
      {swapData && (
        <Route
          path=":swapCode"
          element={
            <SwapPage
              fileUrl={fileUrl}
              setFileUrl={setFileUrl}
              userRole={userRole}
              updateUserRole={updateUserRole}
              swapData={swapData}
              setSwapData={setSwapData}
              updateSwapData={updateSwapData}
              initUsernameA={usernameA}
              initUserUidA={userUidA}
              initUsernameB={usernameB}
              initUserUidB={userUidB}
            />
          }
        />
      )}
    </Routes>
  );
};

export default Mainpage;
