import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext({
    currentUser: null,
    isAuthenticated: false, // This will now be a part of the state
    setIsAuthenticated: () => {}, // Function to change isAuthenticated
    logout: () => {}
});

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                setIsAuthenticated(true); // Set isAuthenticated to true when user is logged in
                localStorage.setItem('userToken', user.uid); // Store user token in local storage
            } else {
                setCurrentUser(null);
                setIsAuthenticated(false); // Set isAuthenticated to false when user is logged out
                localStorage.removeItem('userToken'); // Remove token when user logs out
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const logout = () => {
        setCurrentUser(null);
        setIsAuthenticated(false); // Additionally set isAuthenticated to false when logging out
        localStorage.removeItem('userToken');
    };

    return (
        <AuthContext.Provider value={{ currentUser, isAuthenticated, setIsAuthenticated, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
