import React, { useRef, useState } from "react";
import Card from "../../../components/Card/Card";
import HelpButton from "../../../components/Buttons/HelpButton/HelpButton";
import ForwardButton from "../../../components/Buttons/ForwardButton/ForwardButton";
import "../../../assets/css/SwapCard.css";
import "../../../assets/css/Button.css";
import SwapCardTitle from "../SwapCardTitle";
import SwapCardDescription from "../SwapCardDescription";
import { Button } from "react-bootstrap";
import { SwapData } from "../../../model/SwapData";
import NotificationAlert from "react-notification-alert";

interface CreateSwapCardProps {
  handleCreateTradeClick: () => Promise<string>;
  swapData: SwapData | null;
  onKeyDown?: (value: any) => void;
}

interface Options {
  place: string;
  message: React.ReactNode;
  type: string;
  icon: string;
  autoDismiss: number;
}

const CreateSwapCard: React.FC<CreateSwapCardProps> = ({
  handleCreateTradeClick,
}) => {
  const notificationAlertRef = useRef<any>(null);
  const [alertCount, setAlertCount] = useState<number>(0); // State to track the number of active alerts

  
  //notify -> refactor
  const notify = (place: string) => {
    if (alertCount >= 1) {
      // Prevent more than 1 alerts from being displayed at a time
      return;
    }

    

    const color = Math.floor(Math.random() * 5 + 1);
    let type: string = "";
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    const options: Options = {
      place: place,
      message: (
        <div className="alert-customized">
          <div>
            <h1>
              Welcome to <b>SWAPPD.IO</b>
            </h1>
          </div>
          <div>your digital marketplace</div>
          <div>
            <b>Simple. Secure. Stellar.</b>
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    // Increment alert count
    setAlertCount((currentCount) => currentCount + 1);
    const alert = notificationAlertRef.current?.notificationAlert(options);

    // Auto-decrement alert count after dismissal (assuming autoDismiss is in seconds)
    setTimeout(() => {
      setAlertCount((currentCount) => currentCount - 1);
    }, options.autoDismiss * 1000);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <Card className="swap-card">
        <div className="card-title-container">
          <SwapCardTitle title1="create" title2="Swap" />
        </div>
        <div className="card-content-container">
          <SwapCardDescription swapCardClass="create-swap" description="create">
            <Button
              variant="info"
              className="btn-simple button-transparent swap-message-button"
              onClick={() => notify("tr")}
            >
              Swap
            </Button>
          </SwapCardDescription>
        </div>
        <HelpButton info="createSwapHelp" />

        <ForwardButton onClick={handleCreateTradeClick}>create</ForwardButton>
      </Card>
    </>
  );
};

export default CreateSwapCard;
