/*!

=========================================================
* Black Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import "../../assets/css/Homepage.css";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import "../../assets/css/Register.css";
import NotificationAlert from "react-notification-alert";
import Form from "react-bootstrap/Form";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { LayoutTypes } from "model/LayoutTypes.tsx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "layouts/AuthContext.js";

import {
  handleLogin,
  handleResetPassword,
  notify as notifyLogin,
} from "./Login.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

export const notify = (
  notificationRef,
  { notifyPlace, notifyColor, notifyMessage, callback, username }
) => {
  if (!notificationRef.current) return;

  const messages = {
    success: (
      <div className="alert-customized">
        <div>
          <h1>
            Welcome to <b>SWAPPD.IO</b>
          </h1>
        </div>
        <div>
          Enjoy your Swaps, <b>{username}</b>!
        </div>
      </div>
    ),
    error: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Registration Error</div>
        <div>
          <b>Please try again</b>
        </div>
      </div>
    ),
    errorMailTaken: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Registration Error</div>
        <div>
          <b>Email already in use</b>
        </div>
      </div>
    ),
    errorUserNameTaken: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Registration Error</div>
        <div>
          <b>Username already taken</b>
        </div>
      </div>
    ),
    errorInputMissing: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div>Registration Error</div>
        <div>
          <b>Please fill out all fields</b>
        </div>
      </div>
    ),
    errorUserNamePolicy: (
      <div className="alert-customized">
        <div>
          <b>SWAPPD.IO</b>
          <h1>
            <b>Error</b>
          </h1>
        </div>
        <div><b>Username Guidline</b></div>
        <div>
          <p>Your username should be between 5 and 10 characters long, and contain only letters and numbers. Avoid using offensive or inappropriate terms.
           </p>
        </div>
      </div>
    ),
  };
  

  let type;
  switch (notifyColor) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      type = "info"; // Default case if no color matches
      break;
  }

  const options = {
    place: notifyPlace,
    message: messages[notifyMessage], // Use the message from the 'messages' object or default to the passed message
    type: type,
    icon: "tim-icons icon-bell-55",
    autoDismiss: 2, // Adjust as needed
  };

  notificationRef.current.notificationAlert(options);
  if (callback) callback(); // Call the callback function after the notification
};

export const handleRegister = async ({ username, email, password, db }) => {
  // Preliminary checks
  if (!email || !password || !username) {
    return "MISSING_FIELDS";
  }

  // Database checks
  const usersRef = collection(db, "USERS");
  const usernameSnapshot = await getDocs(
    query(usersRef, where("user_username", "==", username))
  );
  if (!usernameSnapshot.empty) {
    return "USER_TAKEN";
  }

  const emailSnapshot = await getDocs(
    query(usersRef, where("user_email", "==", email))
  );
  if (!emailSnapshot.empty) {
    return "EMAIL_TAKEN";
  }

  try {
    // Attempt registration
    const userCredential = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password
    );
    const user = userCredential.user;
    await sendEmailVerification(user);
    await setDoc(doc(db, "USERS", user.uid), {
      user_uid: user.uid,
      user_email: email,
      user_username: username,
    });
    localStorage.setItem("userToken", user.uid);
    return "SUCCESS";
  } catch (error) {
    console.error("Error registering:", error);
    return "ERROR";
  }
};

const Register = ({ hideInfo = false }) => {
  const [state, setState] = useState({ email: "", password: "", username: "" });
  const notificationAlertRef = useRef(null);
  const [alertCount, setAlertCount] = useState(0);
  const [notifyGone, setNotifyGone] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);
  const [termsError, setTermsError] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const { setIsAuthenticated } = useAuth();
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);

  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggingIn, setisLoggingIn] = useState(false);

  const [isLogin, setIsLogin] = useState(false); // Toggle between Login and Register
  const disallowedUsernames = ["hitler", "badword1", "badword2"]; // Add more as needed

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };




  const isValidUsername = (username) => {
      // Check length
      if (username.length < 5 || username.length > 10) return false;
  
      // Check for invalid characters
      const validChars = /^[a-zA-Z0-9]+$/;
      if (!validChars.test(username)) return false;
  
      // Check against disallowed usernames
      if (disallowedUsernames.includes(username.toLowerCase())) return false;
  
      return true;
  };
  

  const customHandleRegister = async (e) => {
    setIsRegistering(true);
    e.preventDefault();

    // Validate the username
    if (!isValidUsername(state.username)) {
        notify(notificationAlertRef, {
            notifyPlace: "tc",
            notifyColor: 3,
            notifyMessage: "errorUserNamePolicy",
            username: state.username,
            callback: () => setIsRegistering(false)
        });
        return;
    }

    const result = await handleRegister({
        username: state.username,
        email: state.email,
        password: state.password,
        db: getFirestore(),
    });

    switch (result) {
        case "SUCCESS":
            setNotifyGone(true);
            notify(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 2,
                notifyMessage: "success",
                username: state.username,
            });
            break;
        case "USER_TAKEN":
            notify(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorUserNameTaken",
                username: state.username,
            });
            break;
        case "EMAIL_TAKEN":
            notify(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorMailTaken",
                username: state.username,
            });
            break;
        case "MISSING_FIELDS":
            notify(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "errorInputMissing",
                username: state.username,
            });
            break;
        default: // 'ERROR'
            notify(notificationAlertRef, {
                notifyPlace: "tc",
                notifyColor: 3,
                notifyMessage: "error",
                username: state.username,
            });
            break;
    }
    setIsRegistering(false);
};



  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      ((agreedToTerms && !isLogin) || (!agreedToTerms && isLogin))
    ) {
      e.preventDefault(); // Prevent the default action to stop form submission
      customHandleRegister(e);
    }
  };

  const customResetPassword = async () => {
    const result = await handleResetPassword(state.username, getFirestore());
    let emailToReset = state.username;

    switch (result) {
      case "RESET_EMAIL_SENT":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "successPasswordResetMail",
          userEmail: emailToReset,
        });
        break;
      case "EMAIL_MISSING":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailMissing",
        });
        break;
      case "EMAIL_NOT_FOUND":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorEmailNotFound",
        });
        break;
      case "RESET_FAILED":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
      default:
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
        });
        break;
    }
  };

  const customHandleLogin = async (e) => {
    e.preventDefault();
    setisLoggingIn(true);

    const result = await handleLogin({
      username: state.username,
      password: state.password,
      db: getFirestore(),
    });

    switch (result) {
      case "SUCCESS":
        setIsAuthenticated(true);
        setNotifyGone(true);
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 2,
          notifyMessage: "success",
          username: state.username,
        });
        break;
      case "USERNAME_NOT_FOUND":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorUserNameNotFound",
          username: state.username,
        });
        break;
      case "MISSING_FIELDS":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorInputMissing",
          username: state.username,
        });
        break;
      case "WRONG_PASSWORD":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorPasswordIncorrect",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      case "TOO_MANY_REQUESTS":
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "errorTooManyRequests",
          username: state.username,
          customMessage: "Incorrect username or password.",
        });
        break;
      default: // 'ERROR'
        notifyLogin(notificationAlertRef, {
          notifyPlace: "tc",
          notifyColor: 3,
          notifyMessage: "error",
          username: state.username,
        });
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (notifyGone) {
      const timer = setTimeout(() => {
        navigate(`../${LayoutTypes.layout2}/swap/`);
      }, 1000);

      // Cleanup timeout if component unmounts to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [notifyGone, navigate]);

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="dashboard-background"> </div>
      <div className="position-top content ">
        <Container>
          <Row className="justify-content-center">
            <Col lg="15" md="60">
              <Form className="form" onKeyDown={handleKeyDown}>
                <Card className=" reg-log-card">
                  <CardHeader className="text-center">
                    <CardTitle tag="h2" className="pt-2">
                      {isLogin ? "Log in" : "Register"}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {!isLogin && (
                      <>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.usernameFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="username"
                            placeholder="Username"
                            type="text"
                            disabled={isLoggingIn || isRegistering}
                            onFocus={(e) =>
                              setState({ ...state, usernameFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, usernameFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            "mb-3 " +
                            classnames({
                              "input-group-focus": state.emailFocus,
                            })
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="text"
                            disabled={isLoggingIn || isRegistering}
                            onFocus={(e) =>
                              setState({ ...state, emailFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, emailFocus: false })
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </>
                    )}
                    {isLogin && (
                      <InputGroup
                        className={
                          "mb-3 " +
                          classnames({ "input-group-focus": state.emailFocus })
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          placeholder="Username / Email"
                          type="text"
                          disabled={isLoggingIn || isRegistering}
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    )}
                    <InputGroup
                      className={
                        "mb-3 " +
                        classnames({ "input-group-focus": state.passFocus })
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        disabled={isLoggingIn || isRegistering}
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={handleInputChange}
                      />
                    </InputGroup>

                    {!isLogin && (
                      <FormGroup
                        check
                        className={`text-left ${
                          termsError ? "text-danger" : ""
                        }`}
                      >
                        <Label
                          className={`${termsError ? "text-danger" : ""}`}
                          check
                        >
                          <Input
                            type="checkbox"
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                          />
                          <span className="form-check-sign" />I agree to the
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            className={`${termsError ? "text-danger" : ""}`}
                          >
                            <span className="term-cond">
                              terms and conditions
                            </span>
                          </a>
                          .
                        </Label>
                      </FormGroup>
                    )}
                  </CardBody>
                  <CardFooter className="text-center">
                    {isLogin ? (
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={customHandleLogin}
                        size="lg"
                      >
                        Log In
                      </Button>
                    ) : (
                      <Button
                        className="mb-3"
                        color="primary"
                        onClick={customHandleRegister}
                        disabled={!agreedToTerms || isRegistering}
                      >
                        Register
                      </Button>
                    )}

                    {isLogin ? (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(false)}
                          disabled={isRegistering}
                        >
                          Create Account
                        </Button>

                        <h6>
                          <a
                            className="link footer-link"
                            onClick={() => customResetPassword(state.username)}
                            disabled={isRegistering || isLoggingIn}
                          >
                            Reset Password
                          </a>
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <Button
                          className="btn-link"
                          color="default"
                          onClick={() => setIsLogin(true)}
                          disabled={isRegistering}
                        >
                          Already have an account?
                          <br></br>
                          Log in
                        </Button>
                      </div>
                    )}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Register;
