/*!

=========================================================
* Black Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import VectorMap from "views/maps/VectorMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import ReactTables from "views/tables/ReactTables.js";
import RegularTables from "views/tables/RegularTables.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import Wizard from "views/forms/Wizard.js";
import ValidationForms from "views/forms/ValidationForms.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import RegularForms from "views/forms/RegularForms.js";
import Calendar from "views/Calendar.js";
import Widgets from "views/Widgets.js";
import Dashboard from "views/Dashboard.js";
import Buttons from "views/components/Buttons.js";
import SweetAlert from "views/components/SweetAlert.js";
import Notifications from "views/components/Notifications.js";
import Grid from "views/components/Grid.js";
import Typography from "views/components/Typography.js";
import Panels from "views/components/Panels.js";
import Icons from "views/components/Icons.js";
import Pricing from "views/pages/Pricing.js";
import Register from "views/pages/Register.js";
import MySwaps from "views/pages/MySwaps.js";
import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import Rtl from "views/pages/Rtl.js";
import Lock from "views/pages/Lock.js";
import swap from "views/pages/Swappage.js";
import Homepage from "views/pages/Homepage.tsx";
import Mainpage from "views/pages/Mainpage.tsx";
import { LayoutTypes } from "model/LayoutTypes.tsx";
import RegisterLogin from "views/pages/RegisterLogin.js";
import { useAuth } from "layouts/AuthContext.js";
import EmailForm from "views/pages/EmailForm";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    mini: "D",
    component: <Dashboard />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/go-to-dashboard",
    name: "Login/Register",
    rtlName: "لوحة القيادة",
    mini: "D",
    component: <RegisterLogin />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/register-login",
    name: "register-login",
    rtlName: "لوحة القيادة",
    mini: "R",
    component: <RegisterLogin />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/pricing",
    name: "Pricing",
    rtlName: "عالتسعير",
    mini: "P",
    rtlMini: "ع",
    component: <EmailForm />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/my-Swaps",
    name: "My-Swaps",
    rtlName: "تيالجدول الزمني",
    mini: "T",
    rtlMini: "تي",
    component: <MySwaps />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: <Login />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "تسجيل",
    mini: "R",
    rtlMini: "صع",
    component: <Register />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "U",
    rtlMini: "شع",
    component: <Profile />,
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },

  {
    path: "/swap/*",
    name: "SWAPPD.IO",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-cloud-upload-94",
    rtlMini: "شع",
    component: (
      <Mainpage>
        <Homepage />
      </Mainpage>
    ),
    layout: LayoutTypes.layout2,
    showInSidebar: true,
  },

  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-single-copy-04",
    state: "pagesCollapse",
    showInSidebar: true,
    views: [
      {
        path: "/my-dashboard",
        name: "Dashboard",
        rtlName: "لوحة القيادة",
        mini: "DASH",
        component: <Dashboard />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },

      {
        path: "/my-swaps",
        name: "My Swaps",
        rtlName: "تيالجدول الزمني",
        mini: "SWAP",
        rtlMini: "تي",
        component: <MySwaps />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },

      {
        path: "/my-profile",
        name: "Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "Profile",
        rtlMini: "شع",
        component: <Profile />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/my-plan",
        name: "Plan",
        rtlName: "عالتسعير",
        mini: "PLAN",
        rtlMini: "ع",
        component: <EmailForm />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: <Login />,
        layout: LayoutTypes.layout1,
        showInSidebar: false,
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: <Register />,
        layout: LayoutTypes.layout1,
        showInSidebar: false,
      },
    ],
  },
  /* {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    showInSidebar: true,
    views: [ */
  /* {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: <Buttons />,
            layout: LayoutTypes.layout2,
          },
        ],
      }, */

  /*  {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: <Buttons />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: <Grid />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: <Panels />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: <SweetAlert />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: <Notifications />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: <Icons />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: <Typography />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
    ],
  }, */
  /*  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    showInSidebar: true,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: <RegularForms />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: <ExtendedForms />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: <ValidationForms />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: <Wizard />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    showInSidebar: true,
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: <RegularTables />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: <ExtendedTables />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: <ReactTables />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "mapsCollapse",
    showInSidebar: true,
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: <GoogleMaps />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: <FullScreenMap />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: <VectorMap />,
        layout: LayoutTypes.layout2,
        showInSidebar: true,
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: "tim-icons icon-settings",
    component: <Widgets />,
    layout: LayoutTypes.layout2,
    showInSidebar: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: "tim-icons icon-time-alarm",
    component: <Calendar />,
    layout: LayoutTypes.layout2,
    showInSidebar: true,
  }, */

  {
    path: "/swap/*",
    name: "SWAPPD.IO",
    rtlName: "",
    icon: "tim-icons icon-cloud-upload-94",
    component: (
      <Mainpage>
        <Homepage />
      </Mainpage>
    ),
    layout: LayoutTypes.layout1,
    showInSidebar: false,
  },
];

export default routes;
