import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../assets/css/Form.css";
import "../../assets/css/Button.css";
import { SwapData } from "../../model/SwapData";

interface InviteFieldProps {
  swapInviteCode: SwapData;
  disabled?: boolean;
}

function InviteField({ swapInviteCode, disabled = false }: InviteFieldProps) {
  const inviteCode: string = swapInviteCode.SWAP_CODE;
  let toolTipText = "Please upload a file first";

  const renderTooltip = (props: any) => (
    <Tooltip
      id="inviteField-button-tooltip"
      className="my-inviteField-button-tooltip"
      {...props}
    >
      {toolTipText}
    </Tooltip>
  );

  const handleCopyInviteCode = () => {
    navigator.clipboard.writeText(inviteCode);
  };

  // Button with Tooltip for when the button is disabled
  const ButtonWithTooltip = () => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 150, hide: 100 }}
      overlay={renderTooltip}
    >
      <div className="invite-field ">
        <div className="button-temp button-container">
          <Button
            className="invite-button"
            disabled={true}
            onClick={handleCopyInviteCode}
          >
            Invite Code
          </Button>
        </div>
      </div>
    </OverlayTrigger>
  );

  // Standard button without Tooltip for when the button is not disabled
  const StandardButton = () => (
    <div className="invite-field ">
      <div className="button-temp button-container">
        <Button
          className="invite-button"
          disabled={false}
          onClick={handleCopyInviteCode}
        >
          Invite Code
        </Button>
      </div>
    </div>
  );

  return <>{disabled ? <ButtonWithTooltip /> : <StandardButton />}</>;
}

export default InviteField;
