import '../../assets/css/Card.css';
import { ReactNode } from 'react';

interface CardProps {
  className?: string;
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ className, children }) => {
  const cardClass = `card-temp ${className || ''}`; // Combine 'card-temp' with passed 'className'

  return (
    <div className={cardClass}>
      {children}
    </div>

  );
}

export default Card;
