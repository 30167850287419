import React from "react";
import { Button } from "react-bootstrap";
import "../../../assets/css/Button.css";

interface DownloadButtonProps {
  downloadUrl?: string;
  userRole?: "userA" | "userB";
  swapCode: string;
}


export const handleDownload = async (fileUrl: string, swapCode:string) => {

  const getFormattedDate = () => {
    var timestamp = new Date(); // This creates a new Date object with the current date and time
    var day = ("0" + timestamp.getDate()).slice(-2); // Get the day, add leading zero if necessary, and take the last two characters
    var month = ("0" + (timestamp.getMonth() + 1)).slice(-2); // Get the month (0-indexed, add 1 to make it 1-indexed), add leading zero if necessary, and take the last two characters
    var year = timestamp.getFullYear(); // Get the full year
    return `${day}-${month}-${year}`; // Combine in the format "day_month_year"
  };

  function getFileName(fileUrl: string) {
    const parsedUrl = new URL(fileUrl);
    const parts = decodeURIComponent(parsedUrl.pathname).split("/");
    return parts[parts.length - 1];
  }
  if (!fileUrl) return;

  const fileName = getFileName(fileUrl);
  const formattedDate = getFormattedDate();
  const downloadFileName = `swappd-${swapCode}_${fileName}`;

  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};


const DownloadButton: React.FC<DownloadButtonProps> = ({
  downloadUrl,
  userRole,
  swapCode,
}) => {
 
  return (
    <>
      <div className="download-button-container">
        <Button
          className="download-button"
          onClick={() => downloadUrl && handleDownload(downloadUrl,swapCode)}
        >
          Download
        </Button>
      </div>
    </>
  );
};

export default DownloadButton;
