import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";
import { LayoutTypes } from "model/LayoutTypes.tsx";

const Auth = (props) => {
  const location = useLocation(); // This hook returns the current location object
  const [activeRoute, setActiveRoute] = useState("SWAPPD.IO");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    document.documentElement.classList.add("nav-open");
    const currentActiveRoute = getActiveRoute(routes);
    setActiveRoute(currentActiveRoute); // Update state when route changes
  }, [location]); 
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === LayoutTypes.layout1) {
        return <Route path={prop.path} element={prop.component} key={key} exact />;
      }
      return null;
    });
  };

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== "SWAPPD.IO") {
          return collapseActiveRoute;
        }
      } else {
        if (location.pathname.includes(routes[i].layout + routes[i].path)) {
          return routes[i].name;
        }
      }
    }
    return "SWAPPD.IO";
  };

  return (
    <>
      <AuthNavbar brandText={activeRoute} />
      <div className="wrapper wrapper-full-page">
        <div className={"full-page " + getActiveRoute(routes)}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to={`../${LayoutTypes.layout1}/swap`} replace />} />
          </Routes>
          {
            // Conditionally render the Footer based on the path
            location.pathname.includes("/swap/") ||  (isMobile && location.pathname.includes("/swap")) ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    </>
  );
};

export default Auth;
