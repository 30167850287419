import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/ProgressBarElement.css';


interface ProgressBarElementProps {
  customClass?: string;
  progressStatus?: string;
  progressStatusValue?: number;
  id?:string;
}

function ProgressBarElement({ customClass = '', progressStatus = '', progressStatusValue= 0, id='' }: ProgressBarElementProps) {
  return (
    
    <ProgressBar className={`progress-bar-element ${customClass}`} >
    <ProgressBar  variant={progressStatus} now={progressStatusValue}  key={1} id={id} />
{/* <ProgressBar variant="warning" now={20} key={2} />
    <ProgressBar striped variant="danger" now={10} key={3} /> */}
    </ProgressBar>

  );
}

export default ProgressBarElement;

