import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getAuth, Auth, signOut } from 'firebase/auth';

export type { FirebaseApp, Firestore, Auth }; // Export types using 'export type'

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

function initializeFirebase(config: FirebaseConfig): FirebaseApp {
  return initializeApp(config);
}

function getFirebaseAnalytics(app: FirebaseApp) {
  return getAnalytics(app);
}

function getFirebaseFirestore(app: FirebaseApp): Firestore {
  return getFirestore(app);
}

export function getFirebaseAuth(app: FirebaseApp): Auth {
  return getAuth(app);
}

const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyAxO_BcnW7s_YScEQB-RawRW0pAqQhKvQ4',
  authDomain: 'swap-f0f28.firebaseapp.com',
  projectId: 'swap-f0f28',
  storageBucket: 'swap-f0f28.appspot.com',
  messagingSenderId: '1009268615563',
  appId: '1:1009268615563:web:e09f507dcb64e41191a78e',
  measurementId: 'G-PM8XYV5M3Y',
};

const app = initializeFirebase(firebaseConfig);
const analytics = getFirebaseAnalytics(app);
const firestore = getFirebaseFirestore(app);
const auth = getFirebaseAuth(app);

export { app, analytics, firestore, auth, signOut };
