export const SwapState = {
  NOT_JOINED: "not_joined",
  JOINED: "joined",
  UPLOADED: "uploaded",
  PAYMENT_SELECTED: "payment_selected",
  PAYMENT_PRICE_DEFINED:"price_defined",
  PAYMENT_PRICE_PENDING:"price_pending",
  PAYMENT_PRICE_ACCEPTED:"price_accepted",
  PAYMENT_PRICE_DENIED:"price_denied",
  PAYMENT_SUCCESS: "payment_succeeded",
  PAYMENT_FAILURE: "payment_failed",
  CONFIRMED_SWAP: "confirmed_swap",
  DENIED_SWAP: "denied_swap",
};

export const SwapMethod = {
  NONE: "none",
  FILE: "file",
  PAYMENT: "paypal/card/eps",
};

export const SwapItemState = {
  SAFE: "safe",
  NOT_SAFE: "not safe",
  NOT_CHECKED: "not checked",
  CHECKING: "checking...",
  ERROR: "error",
};

export interface SwapUserInfo {
  SWAP_USER: string;
  SWAP_USER_NAME: string;
  SWAP_IP: string;
  SWAP_ITEM_INFO: SwapItemInfo;
  SWAP_STATE: string;
  SWAP_METHOD: string;
}

export interface SwapItemInfo {
  SWAP_ITEM_LINK: string;
  SWAP_ITEM_NAME: string;
  SWAP_ITEM_STATUS: string;
}


export interface SwapPaypalInfo {
  SWAP_PRICE_SET: boolean;
  SWAP_AMOUNT: string;
  SWAP_CURRENCY: string;
  SWAP_PAYEE: string;
  SWAP_PAYER: string;
}

export interface SwapData {
  SWAP_ID: string;
  SWAP_PAYPAL: SwapPaypalInfo;
  SWAP_CODE: string;
  SWAP_DATE: string;
  IS_SWAP_CLOSED: boolean;
  SWAP_USER_A_INFO: SwapUserInfo;
  SWAP_USER_B_INFO: SwapUserInfo;
}
