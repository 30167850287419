import { getStorage, ref, getMetadata } from "firebase/storage";

// Initialize Firebase Storage
const storage = getStorage();

// Function to get metadata from file URL
async function getFileMetadata(fileUrl) {
    if (!fileUrl) return;
    try {
      // Create a reference to the file using its URL
      const fileRef = ref(storage, fileUrl);
  
      // Get the file's metadata
      const metadata = await getMetadata(fileRef);
  
      // Return the metadata object
      return metadata;
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  }

// Function to fetch metadata for both user A and user B files in swapData
async function fetchSwapFilesMetadata(swapData) {
 try {

  
    const fileUrlA = swapData.SWAP_USER_A_INFO.SWAP_ITEM_INFO.SWAP_ITEM_LINK;
    const fileUrlB = swapData.SWAP_USER_B_INFO.SWAP_ITEM_INFO.SWAP_ITEM_LINK;

    // Fetch metadata for both files concurrently for efficiency
    const [fetchedFileMetadataA, fetchedFileMetadataB] = await Promise.all([
      getFileMetadata(fileUrlA),
      getFileMetadata(fileUrlB),
    ]);

    return {
      fetchedFileMetadataA,
      fetchedFileMetadataB,
    };
  } catch (error) {
    console.error("Error fetching swap files metadata:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export { fetchSwapFilesMetadata };
