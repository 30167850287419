import React, { useEffect, useState } from "react";
import "../../assets/css/ProgressBarComponent.css";
import ProgressBarElement from "../../components/ProgressBarElement/ProgressBarElement";
import { SwapState } from "model/SwapData";

interface ProgressBarComponentProps {
  progressValues: {
    progressValue_L: number;
    progressValue_midL: number;
    progressValue_midR: number;
    progressValue_R: number;
  };
  userAUploadState: string;
  userBUploadState: string;
}

function ProgressBarComponent({
  progressValues,
  userAUploadState,
  userBUploadState,
}: ProgressBarComponentProps) {
  const [progressLampStatusLeft, setProgressLampStatusLeft] = useState<string>(
    "progress-status-off"
  );
  const [progressLampStatusMiddle, setProgressLampStatusMiddle] =
    useState<string>("progress-status-off");
  const [progressLampStatusRight, setProgressLampStatusRight] =
    useState<string>("progress-status-off");

  useEffect(() => {
    const delay = 150;

    const timeoutIdLeft = setTimeout(() => {
      setProgressLampStatusLeft(
        userAUploadState === SwapState.UPLOADED ||
          userAUploadState === SwapState.CONFIRMED_SWAP ||
          userAUploadState === SwapState.PAYMENT_PRICE_ACCEPTED ||
          userAUploadState === SwapState.PAYMENT_SUCCESS ||
          userAUploadState === SwapState.DENIED_SWAP ||
          userAUploadState === SwapState.PAYMENT_FAILURE
          ? "progress-status-success"
          : "progress-status-off"
      );
    }, delay);

    const timeoutIdRight = setTimeout(() => {
      setProgressLampStatusRight(
        userBUploadState === SwapState.UPLOADED ||
          userBUploadState === SwapState.CONFIRMED_SWAP ||
          userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED ||
          userBUploadState === SwapState.PAYMENT_SUCCESS ||
          userBUploadState === SwapState.DENIED_SWAP ||
          userBUploadState === SwapState.PAYMENT_FAILURE 
          ? "progress-status-success"
          : userBUploadState === SwapState.PAYMENT_PRICE_DENIED
          ?"progress-status-failure"
          : "progress-status-off"
      );
    }, delay);

    const timeoutIdMiddle = setTimeout(() => {
      if (
        userAUploadState === SwapState.CONFIRMED_SWAP &&
        (userBUploadState === SwapState.CONFIRMED_SWAP ||
          userBUploadState === SwapState.PAYMENT_SUCCESS)
      ) {
        setProgressLampStatusMiddle("progress-status-success");
      } else if (
        userAUploadState === SwapState.DENIED_SWAP ||
        userBUploadState === SwapState.DENIED_SWAP ||
        userBUploadState === SwapState.PAYMENT_FAILURE||
        userBUploadState === SwapState.PAYMENT_PRICE_DENIED
        
      ) {
        setProgressLampStatusMiddle("progress-status-failure");
      } else {
        setProgressLampStatusMiddle("progress-status-off");
      }
    }, delay);

    return () => {
      clearTimeout(timeoutIdLeft);
      clearTimeout(timeoutIdMiddle);
      clearTimeout(timeoutIdRight);
    };
  }, [userAUploadState, userBUploadState]);

  // Determine the progress status based on the value
  const getProgressStatus = (value: number) => {
    return value < 0 ? "danger" : "success"; // Add more conditions here as needed
  };

  // Convert negative values to positive for displaying the progress value
  const getProgressValue = (value: number) => {
    return Math.abs(value);
  };

  return (
    <div className="progress-bar-wrapper">
      <ProgressBarElement
        progressStatus={getProgressStatus(progressValues.progressValue_L)}
        progressStatusValue={getProgressValue(progressValues.progressValue_L)}
        customClass="progress-left"
        id="progress-left"
      />
      <div className={`progress-lamp ${progressLampStatusLeft}`}></div>

      <ProgressBarElement
        progressStatus={getProgressStatus(progressValues.progressValue_midL)}
        progressStatusValue={getProgressValue(
          progressValues.progressValue_midL
        )}
        customClass="progress-middle-left"
        id="progress-middle-left"
      />
      <div className={`progress-lamp ${progressLampStatusMiddle}`}></div>

      <ProgressBarElement
        progressStatus={getProgressStatus(progressValues.progressValue_midR)}
        progressStatusValue={getProgressValue(
          progressValues.progressValue_midR
        )}
        customClass="rotated progress-middle-right"
        id="progress-middle-right"
      />
      <div className={`progress-lamp ${progressLampStatusRight}`}></div>

      <ProgressBarElement
        progressStatus={getProgressStatus(progressValues.progressValue_R)}
        progressStatusValue={getProgressValue(progressValues.progressValue_R)}
        customClass="rotated progress-right"
        id="progress-right"
      />
    </div>
  );
}

export default ProgressBarComponent;
