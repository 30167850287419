import React, { useEffect, useState } from "react";
import "../../assets/css/SwapPage.css";
import "../../assets/css/FileDetail.css";
import FileInput from "../../components/FileInput/FileInput.js";
import ProgressBarComponent from "../../components/ProgressBarComponent/ProgressBarComponent";
import InviteField from "../../components/InviteField/InviteField";
import InputCard from "../../components/InputCard/InputCard";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import DownloadButton from "../../components/Buttons/DownloadButton/DownloadButton.tsx";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useFirebase } from "routes/FireBaseSetup/fireBaseSetup.tsx";
import useUpdateUserDetailsInSwap from "../../utils/getUserInfo";
import useFetchSwapUsernames from "utils/useFetchUserName";
import { Button, Card, Tooltip } from "reactstrap";
import { SwapItemState, SwapMethod, SwapState } from "model/SwapData.tsx";
import { fetchSwapFilesMetadata } from "utils/getMetaDatafromFile.js";
import { OverlayTrigger } from "react-bootstrap";
import SweetAlert from "views/components/SweetAlert.js";
import PriceInputField from "components/PriceInputField/PriceInputField";
import { updateAndUploadSwapData } from "utils/updateAndUploadSwapData";
import PaymentOptions from "./PaymentOptions";
import FileDetail from "views/components/FileDetail";
import fileUserA from "assets/img/file_userA.png";
import fileUserB from "assets/img/file_userB.png";
import paymentUserA from "assets/img/payment_userA.png";
import paymentUserB from "assets/img/payment_userB.png";
import logo from "assets/img/logo_text.png";
import swapArrows from "assets/img/swap_arrows.png";

function SwapPage({
  fileUrl,
  setFileUrl,
  updateUserRole,
  swapData,
  userRole: initialUserRole,
  setSwapData,
  updateSwapData,
  initUsernameA,
  initUserUidA,
  initUsernameB,
  initUserUidB,
}) {
  const [userRole, setUserRole] = useState(initialUserRole || "userA");
  const [showConfirmSwapToastA, setShowConfirmSwapToastA] = useState(false);
  const [showConfirmSwapToastB, setShowConfirmSwapToastB] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [userAUploadState, setUserAUploadState] = useState(
    swapData.SWAP_USER_A_INFO.SWAP_STATE
  );
  const [userBUploadState, setUserBUploadState] = useState(
    swapData.SWAP_USER_B_INFO.SWAP_STATE
  );
  const [userADownloadUrl, setUserADownloadUrl] = useState("");
  const [userBDownloadUrl, setUserBDownloadUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [swapState, setSwapState] = useState(false);
  const [userAFileName, setUserAFileName] = useState("");
  const [userBFileName, setUserBFileName] = useState("");
  const { firestore } = useFirebase();
  const [fileName, setFileName] = useState("");
  const [modalNotice, setModalNotice] = useState(false);
  const [activeUserSide, setActiveUserSide] = useState("");
  const { usernameA, usernameB } = useFetchSwapUsernames(swapData);
  const [fileStatusA, setFileStatusA] = useState("");
  const [fileStatusB, setFileStatusB] = useState("");
  const [showInviteField, setShowInviteField] = useState(false);
  const [toggleCardMobile, setToggleCardMobile] = useState(false);
  const [price, setPrice] = useState(swapData.SWAP_PAYPAL.SWAP_AMOUNT);
  const [mailPaypal, setMailPaypal] = useState("");
  const [currency, setCurrency] = useState(swapData.SWAP_PAYPAL.SWAP_CURRENCY);
  const [isPaypalConfirmed, setIsPaypalConfirmed] = useState(false);
  const [isPaypalInput, setIsPaypalInput] = useState(false);
  const [isPriceConfirmed, setIsPriceConfirmed] = useState(false);
  const [showSetPrice, setShowSetPrice] = useState(false);
  const userAInfo = swapData.SWAP_USER_A_INFO;
  const userBInfo = swapData.SWAP_USER_B_INFO;
  const [showSetPriceButton, setShowSetPriceButton] = useState(false);
  const [showConfirmPayment, setShowConfirmPayment] = useState(false);
  const [showConfirmPaymentButton, setShowConfirmPaymentButton] =
    useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [refreshMetadata, setRefreshMetadata] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [showPaymentOptionsButton, setShowPaymentOptionsButton] =
    useState(false);

  const getSwapMethodPicture = (swapMethod, user) => {
    if (user === "userA") {
      if (swapMethod === SwapMethod.FILE) {
        return fileUserA;
      } else if (swapMethod === SwapMethod.PAYMENT) {
        return paymentUserA;
      }
    } else if (user === "userB") {
      if (swapMethod === SwapMethod.FILE) {
        return fileUserB;
      } else if (swapMethod === SwapMethod.PAYMENT) {
        return paymentUserB;
      }
    }
    return ""; // Default or error image
  };
  /* 
  const setViewportUnits = () => {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };

  useEffect(() => {
    setViewportUnits();
    window.addEventListener('resize', setViewportUnits);
    return () => window.removeEventListener('resize', setViewportUnits);
  }, []); */

  useEffect(() => {
    /*  console.log("Initial userRole in SwapPage:", initialUserRole);
     */ setUserRole(initialUserRole);
  }, [initialUserRole]);

  const bothUsersConfirmed =
    userAUploadState === SwapState.CONFIRMED_SWAP &&
    (userBUploadState === SwapState.CONFIRMED_SWAP ||
      userBUploadState === SwapState.PAYMENT_SUCCESS);

  const shouldShowConfirmButton = () => {
    const userAReadyToShow = [
      SwapState.UPLOADED,
      SwapState.PAYMENT_PRICE_DEFINED,
    ].includes(userAUploadState);
    const userBReadyToShow = [
      SwapState.UPLOADED,
      SwapState.PAYMENT_PRICE_DEFINED,
    ].includes(userBUploadState);

    const isUserAConditionMet =
      userRole === "userA" &&
      userAReadyToShow &&
      (userBUploadState === SwapState.UPLOADED ||
        userBUploadState === SwapState.CONFIRMED_SWAP) &&
      userAUploadState !== SwapState.DENIED_SWAP &&
      userAUploadState !== SwapState.CONFIRMED_SWAP;

    const isUserBConditionMet =
      userRole === "userB" &&
      userBReadyToShow &&
      ((userAUploadState === SwapState.UPLOADED &&
        (userBUploadState === SwapState.UPLOADED ||
          userBUploadState === SwapState.CONFIRMED_SWAP)) ||
        (userAUploadState === SwapState.CONFIRMED_SWAP &&
          userBUploadState === SwapState.UPLOADED)) &&
      userBUploadState !== SwapState.DENIED_SWAP &&
      userBUploadState !== SwapState.CONFIRMED_SWAP;

    return isUserAConditionMet || isUserBConditionMet;
  };

  useEffect(() => {
    if (!swapData.SWAP_ID) return;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "SWAPS", swapData.SWAP_ID),
      (doc) => {
        if (doc.exists()) {
          const updatedSwapData = doc.data();
          setSwapData(updatedSwapData);
        }
      }
    );

    return () => unsubscribe();
  }, [swapData.SWAP_ID]);

  useEffect(() => {
    const shouldShowConfirmToast = () => {
      if (swapData.IS_SWAP_CLOSED) {
        return false;
      }

      const userAReadyToShow = [
        SwapState.UPLOADED,
        SwapState.PAYMENT_PRICE_DEFINED,
      ].includes(userAUploadState);
      const userBReadyToShow = [
        SwapState.UPLOADED,
        SwapState.PAYMENT_PRICE_DEFINED,
      ].includes(userBUploadState);

      const showForUserA =
        userRole === "userA" &&
        userAReadyToShow &&
        (userBUploadState === SwapState.UPLOADED ||
          userBUploadState === SwapState.CONFIRMED_SWAP);
      const showForUserB =
        userRole === "userB" &&
        userBReadyToShow &&
        (userAUploadState === SwapState.UPLOADED ||
          userAUploadState === SwapState.CONFIRMED_SWAP);

      setShowConfirmSwapToastA(showForUserA);
      setShowConfirmSwapToastB(showForUserB);
    };

    shouldShowConfirmToast();
  }, [userAUploadState, userBUploadState, userRole, swapData.IS_SWAP_CLOSED]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useUpdateUserDetailsInSwap(swapData, userRole);
  useFetchSwapUsernames(swapData, userRole);

  useEffect(() => {
    if (swapData) {
      setUserAUploadState(swapData.SWAP_USER_A_INFO.SWAP_STATE);
      setUserBUploadState(swapData.SWAP_USER_B_INFO.SWAP_STATE);
    }
  }, [swapData]);

  useEffect(() => {
    if (swapData) {
      const fetchSwapDataAndStatus = async () => {
        let swapId = swapData.SWAP_ID;
        const db = getFirestore();
        const swapRef = doc(db, "SWAPS", swapId);
        const docSnap = await getDoc(swapRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const statusA =
            data.SWAP_USER_A_INFO?.SWAP_ITEM_INFO?.SWAP_ITEM_STATUS ||
            "status not available";
          const statusB =
            data.SWAP_USER_B_INFO?.SWAP_ITEM_INFO?.SWAP_ITEM_STATUS ||
            "status not available";

          setFileStatusA(statusA);
          setFileStatusB(statusB);
        } else {
          /*     console.log("No such document found with ID:", swapId);
           */
        }
      };

      fetchSwapDataAndStatus();
    }
  }, [modalNotice]);

  useEffect(() => {
    const shouldShowInviteField = () => {
      const userANeedsInvite =
        (userAUploadState === SwapState.UPLOADED &&
          userBUploadState === SwapState.NOT_JOINED) ||
        (userAUploadState === SwapState.CONFIRMED_SWAP &&
          userBUploadState === SwapState.PAYMENT_PRICE_DEFINED &&
          usernameB == "");
      const userBNeedsInvite =
        userBUploadState === SwapState.UPLOADED &&
        userAUploadState === SwapState.NOT_JOINED;

      return userRole === "userA" ? userANeedsInvite : userBNeedsInvite;
    };

    setShowInviteField(shouldShowInviteField());
  }, [userAUploadState, userBUploadState, userRole]);

  useEffect(() => {
    if (userBInfo.SWAP_METHOD == SwapMethod.PAYMENT && !isPriceConfirmed) {
      setShowSetPrice(true);
    }

    if (!swapData.SWAP_PAYPAL.SWAP_AMOUNT == "") {
      setShowConfirmPayment(true);
    }
    if (
      userBUploadState == SwapState.PAYMENT_PRICE_DEFINED &&
      isPriceConfirmed
    ) {
      setPrice(swapData.SWAP_PAYPAL.SWAP_AMOUNT);

      if (swapData.SWAP_PAYPAL.SWAP_AMOUNT == "EUR") {
        setCurrency("€");
      } else if (swapData.SWAP_PAYPAL.SWAP_AMOUNT == "USD") {
        setCurrency("$");
      } else {
        return;
      }
    }
  }, [swapData]);

  useEffect(() => {
    const bothUsersConfirmed =
      userAUploadState === SwapState.CONFIRMED_SWAP &&
      (userBUploadState === SwapState.CONFIRMED_SWAP ||
        userBUploadState === SwapState.PAYMENT_SUCCESS);

    if (bothUsersConfirmed) {
      setShowConfirmSwapToastA(false);
      setShowConfirmSwapToastB(false);
      setShowSuccessToast(true);
      fetchDownloadUrls();

      const updatedSwapData = {
        ...swapData,
        IS_SWAP_CLOSED: true,
      };
      setSwapData(updatedSwapData);
      const docRef = doc(getFirestore(), "SWAPS", swapData.SWAP_ID);
      setDoc(docRef, updatedSwapData, { merge: true });
    }
    if (swapData) {
      setUserAFileName(
        userAInfo?.SWAP_ITEM_INFO.SWAP_ITEM_NAME !== ""
          ? userAInfo?.SWAP_ITEM_INFO.SWAP_ITEM_NAME
          : userAInfo?.SWAP_ITEM_INFO.SWAP_METHOD !== ""
          ? "Paypal"
          : ""
      );

      setUserBFileName(
        userBInfo?.SWAP_ITEM_INFO.SWAP_ITEM_NAME !== ""
          ? userBInfo?.SWAP_ITEM_INFO.SWAP_ITEM_NAME
          : userBInfo?.SWAP_ITEM_INFO.SWAP_METHOD !== ""
          ? "Paypal"
          : ""
      );
    }
    const shouldShowFailureToast =
      userAUploadState === SwapState.DENIED_SWAP ||
      userBUploadState === SwapState.DENIED_SWAP ||
      userBUploadState === SwapState.PAYMENT_FAILURE ||
      userBUploadState === SwapState.PAYMENT_PRICE_DENIED;

    setShowFailureToast(shouldShowFailureToast);
  }, [userAUploadState, userBUploadState]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "SWAPS", swapData.SWAP_ID),
      (doc) => {
        const data = doc.data();
        setSwapState(data);
      }
    );

    return () => unsubscribe();
  }, [swapData.SWAP_ID]);

  const handleConfirmSwap = async (role) => {
    const newState = SwapState.CONFIRMED_SWAP;

    const swapFieldToUpdate =
      role === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO";
    let updatedSwapData = {
      ...swapData,
      [swapFieldToUpdate]: {
        ...swapData[swapFieldToUpdate],
        SWAP_STATE: newState,
      },
    };

    const isUserAConfirmed =
      role === "userA"
        ? true
        : swapData.SWAP_USER_A_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP;
    const isUserBConfirmed =
      role === "userB"
        ? true
        : swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP ||
          swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.PAYMENT_SUCCESS;

    if (isUserAConfirmed && isUserBConfirmed) {
      updatedSwapData = {
        ...updatedSwapData,
        IS_SWAP_CLOSED: true,
      };
    }

    const docRef = doc(getFirestore(), "SWAPS", swapData.SWAP_ID);
    await setDoc(docRef, updatedSwapData);

    setSwapData(updatedSwapData);
  };

  const handleDenySwap = async (role) => {
    fetchLatestSwapData();

    let newState = "";
    const swapFieldToUpdate =
      role === "userA" ? "SWAP_USER_A_INFO" : "SWAP_USER_B_INFO";
    const swapDataUser =
      role === "userA" ? swapData.SWAP_USER_A_INFO : swapData.SWAP_USER_B_INFO;

    if (swapDataUser.SWAP_METHOD === SwapMethod.FILE) {
      newState = SwapState.DENIED_SWAP;
    } else if (swapDataUser.SWAP_METHOD === SwapMethod.PAYMENT) {
      newState = SwapState.PAYMENT_PRICE_DENIED;
    } else {
      newState = SwapState.DENIED_SWAP;
      //This is a workaround because of incorrect State update (file - payment)
    }

    const updatedSwapData = {
      ...swapData,
      IS_SWAP_CLOSED: true,
      [swapFieldToUpdate]: {
        ...swapData[swapFieldToUpdate],
        SWAP_STATE: newState,
      },
    };

    await setDoc(
      doc(getFirestore(), "SWAPS", swapData.SWAP_ID),
      updatedSwapData
    );
    if (role === "userA") {
      setShowConfirmSwapToastA(false);
      setShowFailureToast(true);
    } else if (role === "userB") {
      setShowConfirmSwapToastB(false);
      setShowFailureToast(true);
    }
  };

  const [progressValues, setProgressValues] = useState({
    progressValue_L: 0,
    progressValue_midL: 0,
    progressValue_midR: 0,
    progressValue_R: 0,
  });

  const handleFileUpload = async (file, role) => {
    if (!swapData || !file) return;
    setSwapData(swapData);
    if (role === "userA") {
      setUserAFileName(file.name);
    }
    if (role === "userB") {
      setUserBFileName(file.name);
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `SWAPS/${swapData.SWAP_ID}/${file.name}`);
      const uploadResult = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(uploadResult.ref);
      updateSwapData(downloadUrl, file);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleRoleSwitch = () => {
    setUserRole(userRole === "userA" ? "userB" : "userA");
    updateUserRole(userRole === "userA" ? "userB" : "userA");
  };

  const fetchLatestSwapData = async () => {
    if (swapData && swapData.SWAP_ID) {
      const db = getFirestore();
      const docRef = doc(db, "SWAPS", swapData.SWAP_ID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const latestSwapData = docSnap.data();
        setSwapData(latestSwapData);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "SWAPS", swapData.SWAP_ID),
      (doc) => {
        if (doc.exists()) {
          const updatedSwapData = doc.data();
          setSwapData(updatedSwapData);

          const userAState = updatedSwapData.SWAP_USER_A_INFO.SWAP_STATE;
          const userBState = updatedSwapData.SWAP_USER_B_INFO.SWAP_STATE;

          const bothUploaded =
            userAState === SwapState.UPLOADED &&
            userBState === SwapState.UPLOADED;
          const oneConfirmedOtherUploaded =
            (userAState === SwapState.CONFIRMED_SWAP &&
              userBState === SwapState.UPLOADED) ||
            (userBState === SwapState.CONFIRMED_SWAP &&
              userAState === SwapState.UPLOADED);

          setShowConfirmSwapToastA(bothUploaded || oneConfirmedOtherUploaded);
          setShowConfirmSwapToastB(bothUploaded || oneConfirmedOtherUploaded);
        }
      }
    );

    return () => unsubscribe();
  }, [swapData.SWAP_ID, userRole]);

  const fetchDownloadUrls = async () => {
    const storage = getStorage();

    try {
      if (swapData.SWAP_USER_A_INFO.SWAP_METHOD === SwapMethod.FILE) {
        const userAFileRef = ref(
          storage,
          swapData.SWAP_USER_A_INFO.SWAP_ITEM_INFO.SWAP_ITEM_LINK
        );
        const userADownloadUrl = await getDownloadURL(userAFileRef);
        setUserADownloadUrl(userADownloadUrl);
      } else {
        setUserADownloadUrl(swapData.SWAP_USER_A_INFO.SWAP_METHOD);
      }

      if (swapData.SWAP_USER_B_INFO.SWAP_METHOD === SwapMethod.FILE) {
        const userBFileRef = ref(
          storage,
          swapData.SWAP_USER_B_INFO.SWAP_ITEM_INFO.SWAP_ITEM_LINK
        );
        const userBDownloadUrl = await getDownloadURL(userBFileRef);
        setUserBDownloadUrl(userBDownloadUrl);
      } else {
        setUserBDownloadUrl(swapData.SWAP_USER_B_INFO.SWAP_METHOD);
      }
    } catch (error) {
      console.error("Error fetching download URLs", error);
    }
  };

  useEffect(() => {
    fetchLatestSwapData();
  }, [swapData.SWAP_ID, userAUploadState, userBUploadState]);

  useEffect(() => {
    const newProgressValues = { ...progressValues };
    if (userAUploadState === SwapState.UPLOADED) {
      newProgressValues.progressValue_L = 100;
    }
    if (userAUploadState === SwapState.CONFIRMED_SWAP) {
      newProgressValues.progressValue_L = 100;
      newProgressValues.progressValue_midL = 100;
    }
    if (userAUploadState === SwapState.DENIED_SWAP) {
      newProgressValues.progressValue_L = 100;
      newProgressValues.progressValue_midL = -100;
    }
    if (
      userBUploadState === SwapState.UPLOADED ||
      userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
    ) {
      newProgressValues.progressValue_R = 100;
    }
    if (userBUploadState === SwapState.PAYMENT_PRICE_DENIED) {
      newProgressValues.progressValue_R = -100;
    }
    if (
      userBUploadState === SwapState.CONFIRMED_SWAP ||
      userBUploadState === SwapState.PAYMENT_SUCCESS
    ) {
      newProgressValues.progressValue_R = 100;
      newProgressValues.progressValue_midR = 100;
    }
    if (
      userBUploadState === SwapState.DENIED_SWAP ||
      userBUploadState === SwapState.PAYMENT_FAILURE
    ) {
      newProgressValues.progressValue_R = 100;
      newProgressValues.progressValue_midR = -100;
    }

    setProgressValues(newProgressValues);
  }, [swapData, userAUploadState, userBUploadState]);

  const hideAlert = () => {
    setShowConfirmSwapToastA(false);
    setShowConfirmSwapToastB(false);
    setShowFailureToast(false);
    setShowSuccessToast(false);
  };

  const toggleModalNotice = (userSide) => {
    setRefreshMetadata((prev) => !prev);
    setActiveUserSide(userSide);
    getFileMetadata(fileUrl);
    setModalNotice(!modalNotice);
  };

  const storage = getStorage();

  async function getFileMetadata(fileUrl) {
    if (!fileUrl) return;
    setRefreshMetadata((prev) => !prev);

    try {
      const fileRef = ref(storage, fileUrl);
      const metadata = await getMetadata(fileRef);
      return metadata;
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  }

  const toggleCardViewHandler = () => {
    if (window.innerWidth <= 600) {
      setToggleCardMobile((prevState) => !prevState);
     
    }
  };

  const handleCopyInviteCode = () => {
    navigator.clipboard.writeText(swapData.SWAP_CODE);
  };

  const handleConfirmPrice = async (userRole) => {
    const swapId = swapData.SWAP_ID;
    const updates = {
      additionalFields: {
        SWAP_USER_B_INFO: { SWAP_STATE: SwapState.PAYMENT_PRICE_ACCEPTED },
      },
    };

    try {
      await updateAndUploadSwapData(swapId, updates);
      setUserBUploadState(SwapState.PAYMENT_PRICE_ACCEPTED);
    } catch (error) {
      console.error("Failed to update price state:", error);
    }
  };

  const setPriceHandler = (price, mail) => {
    setShowSetPrice(false);
    setShowSetPriceButton(false);
    setIsPriceConfirmed(true);

    const swapId = swapData.SWAP_ID;
    const updates = {
      additionalFields: {
        SWAP_PAYPAL: {
          SWAP_PRICE_SET: true,
          SWAP_AMOUNT: `${price.amount}`,
          SWAP_CURRENCY: `${price.currency}`,
          SWAP_PAYEE: `${mail}`,
        },
        SWAP_USER_A_INFO: {
          SWAP_STATE: SwapState.CONFIRMED_SWAP,
        },
        SWAP_USER_B_INFO: {
          SWAP_STATE: SwapState.PAYMENT_PRICE_DEFINED,
        },
      },
    };
    updateAndUploadSwapData(swapId, updates);
  };

  const handlePayPalSelected = (isPaypalInput) => {
    /*    console.log("PayPal selected:", isPaypalInput);
     */
  };

  const handleSetShowPaymentOptions = () => {
    setShowPaymentOptions(true);
    setShowConfirmPayment(false);
    setShowConfirmPaymentButton(false); // Hide confirm button when payment options are shown
  };

  const handleSetShowConfirmPayment = () => {
    setShowPaymentOptions(false);
    setShowConfirmPayment(true);
    setShowConfirmPaymentButton(false); // Hide confirm button when payment options are shown
  };
  const handlePaymentSuccess = async (details) => {
    setShowPaymentOptionsButton(false);
    try {
      const swapId = swapData.SWAP_ID;
      const payerEmail =
        details.payer && details.payer.email_address
          ? details.payer.email_address
          : "unknown";
      const updates = {
        additionalFields: {
          SWAP_PAYPAL: {
            SWAP_PAYER: payerEmail,
          },
          SWAP_USER_B_INFO: { SWAP_STATE: SwapState.PAYMENT_SUCCESS },
        },
      };

      await updateAndUploadSwapData(swapId, updates);
      await updateUIStates();
      setShowSuccessToast(true);
    } catch (error) {
      console.error("Failed to update UI states:", error);
    }
  };

  function updateUIStates() {
    return new Promise((resolve) => {
      setShowConfirmPayment(false);
      setShowPaymentOptions(false);
      setShowConfirmPaymentButton(false);
      resolve();
    });
  }

  const handlePaymentCancel = async (paymentDetails) => {
    setShowPaymentOptions(false);
    setShowPaymentOptionsButton(false);
    const swapId = swapData.SWAP_ID;
    const updates = {
      additionalFields: {
        SWAP_USER_B_INFO: { SWAP_STATE: SwapState.PAYMENT_FAILURE },
      },
    };

    try {
      await updateAndUploadSwapData(swapId, updates);
      await updateUIStates();
      setShowFailureToast(true);
    } catch (error) {
      console.error("Failed to update UI states:", error);
    }
  };

  return (
    <>
      <div className="swappage-container">
        <div className="swappage-banner-wrapper">
          <Card
            className="user-name-card swappage-banner-card"
            onClick={handleCopyInviteCode}
          >
            <p>{swapData.SWAP_ID}</p>
          </Card>
        </div>
        <div className="swappage">
          {userRole === "userB" && (
            <div
              className="file-input-wrapper"
              id={toggleCardMobile ? "" : "hideOnMobile"}
            >
              <i className="tim-icons my-swappage-icon icon-refresh-02" />

              <div className="user-name-card" onClick={toggleCardViewHandler}>
             
                  <img
                    className="file-image-arrow"
                    alt="uploaded"
                    src={swapArrows}
                  />
              
                {usernameA || initUsernameA}
              </div>

              <InputCard
                userSide="userA"
                fileImage={
                  getSwapMethodPicture(
                    swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                    "userA"
                  ) ? (
                    <img
                      className="file-image"
                      alt="uploaded"
                      src={getSwapMethodPicture(
                        swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                        "userA"
                      )}
                    />
                  ) : null
                }
                onClick={() => toggleModalNotice("userA")}
                defaultText={userAFileName || ""}
                swapMethod={swapData.SWAP_USER_A_INFO.SWAP_METHOD} // Pass the swapMethod here
              />

              {bothUsersConfirmed ? (
                <DownloadButton
                  swapCode={swapData.SWAP_CODE}
                  downloadUrl={userADownloadUrl}
                  userRole={userRole}
                />
              ) : (
                <p className="user-card-status">
                  {userAUploadState === SwapState.UPLOADED
                    ? "Uploaded"
                    : userAUploadState === SwapState.CONFIRMED_SWAP
                    ? "Swap confirmed"
                    : userAUploadState === SwapState.DENIED_SWAP
                    ? "Swap denied"
                    : userAUploadState === SwapState.PAYMENT_PRICE_PENDING
                    ? "waiting for price"
                    : userAUploadState === SwapState.PAYMENT_PRICE_DEFINED ||
                      userAUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                    ? `${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`
                    : "Not uploaded"}
                </p>
              )}
            </div>
          )}
          {userRole === "userA" && (
            <div
              className="file-input-wrapper"
              id={!toggleCardMobile ? "" : "hideOnMobile"}
            >
              <i className="tim-icons my-swappage-icon icon-refresh-02" />
              <div className="user-name-card" onClick={toggleCardViewHandler}>
            
                  <img
                    className="file-image-arrow"
                    alt="uploaded"
                    src={swapArrows}
                  />
                

                {usernameA || initUsernameA}
              </div>

              {swapData.SWAP_USER_A_INFO.SWAP_STATE === SwapState.UPLOADED ||
              swapData.SWAP_USER_A_INFO.SWAP_STATE ===
                SwapState.CONFIRMED_SWAP ||
              swapData.SWAP_USER_A_INFO.SWAP_STATE === SwapState.DENIED_SWAP ||
              swapData.SWAP_USER_A_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_PENDING ||
              swapData.SWAP_USER_A_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_DEFINED ? (
                <div className="file-input-wrapper">
                  <InputCard
                    userSide="userA"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                        "userA"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                            "userA"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userA")}
                    defaultText={userAFileName || ""}
                    swapMethod={swapData.SWAP_USER_A_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />

                  <p className="user-card-status">
                    {userAUploadState === SwapState.UPLOADED
                      ? "Uploaded"
                      : userAUploadState === SwapState.CONFIRMED_SWAP
                      ? "Swap confirmed"
                      : userAUploadState === SwapState.DENIED_SWAP
                      ? "Swap denied"
                      : userAUploadState === SwapState.PAYMENT_PRICE_PENDING
                      ? `waiting for price`
                      : userAUploadState === SwapState.PAYMENT_PRICE_DEFINED ||
                        userAUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                      ? `${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`
                      : "Not uploaded"}
                  </p>
                </div>
              ) : swapData.SWAP_PAYPAL.SWAP_PRICE_SET ? (
                <>
                  <InputCard
                    userSide="userA"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                        "userA"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_A_INFO.SWAP_METHOD,
                            "userA"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userA")}
                    defaultText={userAFileName || ""}
                    swapMethod={swapData.SWAP_USER_A_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />

                  {userBUploadState === SwapState.JOINED ? (
                    <p className="user-card-status">Payment</p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <FileInput
                  swapData={swapData}
                  setSwapData={setSwapData}
                  id="sideA"
                  onUploadComplete={(file) => handleFileUpload(file, "userA")}
                  userRole={userRole}
                  onPayPalSelected={handlePayPalSelected}
                  price={price}
                />
              )}
            </div>
          )}
          <div className="progress-bar-container">
            <ProgressBarComponent
              userBUploadState={userBUploadState}
              userAUploadState={userAUploadState}
              progressValues={progressValues}
            />
          </div>
          {userRole === "userB" && (
            <div
              className="file-input-wrapper"
              id={!toggleCardMobile ? "" : "hideOnMobile"}
            >
              <i className="tim-icons my-swappage-icon icon-refresh-02" />
              <div className="user-name-card" onClick={toggleCardViewHandler}>
             
                  <img
                    className="file-image-arrow"
                    alt="uploaded"
                    src={swapArrows}
                  />
              
                {usernameB || initUsernameB}
              </div>
              {swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.UPLOADED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.CONFIRMED_SWAP ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.DENIED_SWAP ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_PENDING ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_DEFINED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_ACCEPTED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_SUCCESS ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_DENIED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_FAILURE ? (
                <div className="file-input-wrapper">
                  <InputCard
                    userSide="userB"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                        "userB"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                            "userB"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userB")}
                    defaultText={userBFileName || ""}
                    swapMethod={swapData.SWAP_USER_B_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />

                  <p className="user-card-status">
                    {userBUploadState === SwapState.UPLOADED
                      ? "Uploaded"
                      : userBUploadState === SwapState.CONFIRMED_SWAP
                      ? "Swap confirmed"
                      : userBUploadState === SwapState.DENIED_SWAP
                      ? "Swap denied"
                      : userBUploadState === SwapState.PAYMENT_PRICE_PENDING
                      ? `waiting for price`
                      : userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                      ? "Price accepted"
                      : userBUploadState === SwapState.PAYMENT_SUCCESS
                      ? `Paid`
                      : userBUploadState === SwapState.PAYMENT_PRICE_DENIED
                      ? "Price Denied"
                      : userBUploadState === SwapState.PAYMENT_FAILURE
                      ? "Payment failed"
                      : userBUploadState === SwapState.PAYMENT_PRICE_DEFINED ||
                        userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                      ? `${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`
                      : "Not uploaded"}
                  </p>
                </div>
              ) : swapData.SWAP_PAYPAL.SWAP_PRICE_SET ? (
                <>
                  <InputCard
                    userSide="userB"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                        "userB"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                            "userB"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userB")}
                    defaultText={userBFileName || ""}
                    swapMethod={swapData.SWAP_USER_B_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />
                  {userBUploadState === SwapState.JOINED ? (
                    <p className="user-card-status">{`${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`}</p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <FileInput
                  swapData={swapData}
                  setSwapData={setSwapData}
                  id="sideB"
                  onUploadComplete={(file) => handleFileUpload(file, "userB")}
                  userRole={userRole}
                  onPayPalSelected={handlePayPalSelected}
                  price={price}
                />
              )}
            </div>
          )}

          {userRole === "userA" && (
            <div
              className="file-input-wrapper"
              id={toggleCardMobile ? "" : "hideOnMobile"}
            >
              <i className="tim-icons my-swappage-icon icon-refresh-02" />
              <div className="user-name-card " onClick={toggleCardViewHandler}>
         
                  <img
                    className="file-image-arrow"
                    alt="uploaded"
                    src={swapArrows}
                  />
             
                {usernameB || initUsernameB}
              </div>
              {swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.UPLOADED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.CONFIRMED_SWAP ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.DENIED_SWAP ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_PENDING ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_DEFINED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_ACCEPTED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_SUCCESS ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_PRICE_DENIED ||
              swapData.SWAP_USER_B_INFO.SWAP_STATE ===
                SwapState.PAYMENT_FAILURE ? (
                <div className="file-input-wrapper">
                  <InputCard
                    userSide="userB"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                        "userB"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                            "userB"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userB")}
                    defaultText={userBFileName || ""}
                    swapMethod={swapData.SWAP_USER_B_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />
                  {bothUsersConfirmed ? (
                    swapData.SWAP_USER_B_INFO.SWAP_METHOD ===
                    SwapMethod.PAYMENT ? (
                      <p className="user-card-status">Paid</p>
                    ) : (
                      <DownloadButton
                        swapCode={swapData.SWAP_CODE}
                        downloadUrl={userBDownloadUrl}
                        userRole={userRole}
                      />
                    )
                  ) : (
                    <p className="user-card-status">
                      {userBUploadState === SwapState.UPLOADED
                        ? "Uploaded"
                        : userBUploadState === SwapState.CONFIRMED_SWAP
                        ? "Swap confirmed"
                        : userBUploadState === SwapState.DENIED_SWAP
                        ? "Swap denied"
                        : userBUploadState === SwapState.PAYMENT_PRICE_PENDING
                        ? `waiting for price`
                        : userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                        ? "Price accepted"
                        : userBUploadState === SwapState.PAYMENT_SUCCESS
                        ? "Paid"
                        : userBUploadState === SwapState.PAYMENT_PRICE_DENIED
                        ? "Price Denied"
                        : userBUploadState === SwapState.PAYMENT_FAILURE
                        ? "Payment failed"
                        : userBUploadState ===
                            SwapState.PAYMENT_PRICE_DEFINED ||
                          userBUploadState === SwapState.PAYMENT_PRICE_ACCEPTED
                        ? `${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`
                        : "Not uploaded"}
                    </p>
                  )}
                </div>
              ) : swapData.SWAP_PAYPAL.SWAP_PRICE_SET ? (
                <>
                  <InputCard
                    userSide="userB"
                    fileImage={
                      getSwapMethodPicture(
                        swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                        "userB"
                      ) ? (
                        <img
                          className="file-image"
                          alt="uploaded"
                          src={getSwapMethodPicture(
                            swapData.SWAP_USER_B_INFO.SWAP_METHOD,
                            "userB"
                          )}
                        />
                      ) : null
                    }
                    onClick={() => toggleModalNotice("userB")}
                    defaultText={userBFileName || ""}
                    swapMethod={swapData.SWAP_USER_B_INFO.SWAP_METHOD} // Pass the swapMethod here
                  />
                  {userBUploadState === SwapState.JOINED ? (
                    <p className="user-card-status">{`${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`}</p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <InputCard
                  userSide="userB"
                  onClick={() => toggleModalNotice("userB")}
                />
              )}
            </div>
          )}
        </div>
        <div className="swappage-invite">
          {showInviteField ? (
            <InviteField swapInviteCode={swapData} />
          ) : shouldShowConfirmButton() ? (
            <Button
              id="confirm-button"
              onClick={() => {
                userRole === "userA"
                  ? setShowConfirmSwapToastA(true)
                  : setShowConfirmSwapToastB(true);
              }}
            >
              Confirm Swap
            </Button>
          ) : userAUploadState === SwapState.JOINED ||
            (userAUploadState === SwapState.UPLOADED &&
              userBUploadState === SwapState.NOT_JOINED) ? (
            <InviteField disabled swapInviteCode={swapData} />
          ) : (
            ""
          )}
        </div>

        {showConfirmSwapToastA &&
          userRole == "userA" &&
          userAUploadState === SwapState.UPLOADED && (
            <ReactBSAlert
              customClass="toast-alert-temp confirm-toast-style"
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => {
                handleConfirmSwap(userRole);
                setShowConfirmSwapToastA(false);
              }}
              onCancel={() => {
                handleDenySwap(userRole);
                setShowConfirmSwapToastA(false);
              }}
              onClose={() => {
                setShowConfirmSwapToastA(false);
              }}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Confirm"
              cancelBtnText="Deny"
              showCancel
              closeOnClickOutside={false}
              btnSize=""
            >
              Do you want to confirm this swap?
              <br></br>
              <i
                onClick={() => {
                  setShowConfirmSwapToastA(false);
                }}
                className="tim-icons my-confirmToast-icon icon-simple-remove"
              />
            </ReactBSAlert>
          )}
        {showConfirmSwapToastB &&
          userRole == "userB" &&
          userBUploadState === SwapState.UPLOADED && (
            <ReactBSAlert
              customClass="toast-alert-temp confirm-toast-style"
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => {
                handleConfirmSwap(userRole);
                setShowConfirmSwapToastB(false);
              }}
              onCancel={() => {
                handleDenySwap(userRole);
                setShowConfirmSwapToastB(false);
              }}
              onClose={() => {
                setShowConfirmSwapToastB(false);
              }}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Confirm"
              cancelBtnText="Deny"
              showCancel
              closeOnClickOutside={false}
              btnSize=""
            >
              Do you want to confirm this swap?
              <br></br>
              <i
                onClick={() => {
                  setShowConfirmSwapToastB(false);
                }}
                className="tim-icons my-confirmToast-icon icon-simple-remove"
              />
            </ReactBSAlert>
          )}

        {showSuccessToast && (
          <ReactBSAlert
            customClass="toast-alert-temp"
            style={{ display: "block", marginTop: "-100px" }}
            title="Swap succeeded!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            onClose={() => {}}
            confirmBtnBsStyle="success"
            btnSize=""
          ></ReactBSAlert>
        )}
        {showFailureToast && (
          <ReactBSAlert
            customClass="toast-alert-temp"
            style={{ display: "block", marginTop: "-100px" }}
            title="Swap failed!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            onClose={() => {}}
            confirmBtnBsStyle="error"
            btnSize=""
          ></ReactBSAlert>
        )}
        {modalNotice &&
          activeUserSide === "userA" &&
          userAFileName &&
          swapData.SWAP_USER_A_INFO.SWAP_METHOD === SwapMethod.FILE && (
            <ReactBSAlert
              customClass="toast-alert-temp"
              style={{ display: "block", marginTop: "100px" }}
              title={userAFileName}
              onConfirm={() => setModalNotice(false)}
              onCancel={() => setModalNotice(false)}
              confirmBtnBsStyle="info"
              confirmBtnText="Close"
              showCancel={false}
            >
              <FileDetail
                userRole={"userA"}
                swapData={swapData}
                userAUploadState={userAUploadState}
                userBUploadState={userBUploadState}
                fileStatusA={fileStatusA}
                fileStatusB={fileStatusB}
                refreshMetadata={refreshMetadata}
              ></FileDetail>
            </ReactBSAlert>
          )}

        {modalNotice &&
          activeUserSide === "userB" &&
          userBFileName &&
          swapData.SWAP_USER_B_INFO.SWAP_METHOD === SwapMethod.FILE && (
            <ReactBSAlert
              customClass="toast-alert-temp"
              style={{ display: "block", marginTop: "100px" }}
              title={userBFileName}
              onConfirm={() => setModalNotice(false)}
              onCancel={() => setModalNotice(false)}
              confirmBtnBsStyle="info"
              confirmBtnText="Close"
              showCancel={false}
            >
              <FileDetail
                userRole={"userB"}
                swapData={swapData}
                userAUploadState={userAUploadState}
                userBUploadState={userBUploadState}
                fileStatusA={fileStatusA}
                fileStatusB={fileStatusB}
                refreshMetadata={refreshMetadata}
              ></FileDetail>
            </ReactBSAlert>
          )}
        {userAUploadState === SwapState.UPLOADED &&
          showSetPrice &&
          userRole === "userA" && (
            <>
              <ReactBSAlert
                type="controlled"
                title=""
                customClass="set-price-alert"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Enter price for your item"
                onConfirm={() => {
                  setShowSetPrice(false);
                  setShowSetPriceButton(true);
                }}
                onCancel={() => {
                  setShowSetPrice(false);
                  setShowSetPriceButton(true);
                }}
                onClose={() => {
                  setShowSetPrice(false);
                  setShowSetPriceButton(true);
                }}
                confirmBtnBsStyle="info"
                confirmBtnText="Close"
              >
                <PriceInputField
                  setPrice={setPrice}
                  setMailPaypal={setMailPaypal}
                  onConfirm={() => {
                    setPriceHandler(price, mailPaypal);
                    handleConfirmSwap("userA");
                    setShowSetPrice(false);
                    setShowSetPriceButton(true);
                  }}
                />
              </ReactBSAlert>
            </>
          )}

        {((showSetPriceButton &&
          swapData.SWAP_USER_B_INFO.SWAP_STATE === SwapState.NOT_JOINED) ||
          (swapData.SWAP_USER_B_INFO.SWAP_METHOD !== SwapMethod.FILE &&
            !isPriceConfirmed &&
            userAUploadState === SwapState.UPLOADED &&
            userRole === "userA")) && (
          <div className="swappage-invite">
            <Button
              id="set-price-button"
              onClick={() => {
                setShowSetPrice(true);
                setShowSetPriceButton(true);
              }}
            >
              Set Price
            </Button>
          </div>
        )}

        {showConfirmPayment &&
          !showPaymentOptions &&
          userRole === "userB" &&
          swapData.IS_SWAP_CLOSED == false &&
          swapData.SWAP_USER_B_INFO.SWAP_STATE !== SwapState.DENIED_SWAP &&
          swapData.SWAP_USER_A_INFO.SWAP_STATE !== SwapState.DENIED_SWAP && (
            <>
              <div className="pay-card-wrapper">
                <ReactBSAlert
                  title=""
                  type="controlled"
                  customClass="set-price-alert"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Confirm Payment"
                  onConfirm={() => {
                    setShowConfirmPayment(false);
                    setShowConfirmPaymentButton(true);
                  }}
                  onCancel={() => {
                    setShowConfirmPayment(false);
                    setShowConfirmPaymentButton(true);
                  }}
                  confirmBtnBsStyle="info"
                  confirmBtnText="Close"
                >
                  {swapData.IS_SWAP_CLOSED == false && (
                    <>
                      <div className="pay-card-wrapper">
                        <div className="price-card-title">
                          <p> Swap Price </p>
                          <p className="price-title">
                            {swapData.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                            {swapData.SWAP_PAYPAL.SWAP_CURRENCY}
                          </p>
                        </div>
                        <div className="price-confirm-button-container">
                          <Button
                            className="btn-deny button-temp button-container set-price-button-element btn-danger"
                            onClick={() => {
                              handleDenySwap("userB");
                              setShowPaymentOptions(false);
                              setShowConfirmPaymentButton(false);
                              setShowConfirmPayment(false);
                            }}
                          >
                            Deny
                          </Button>
                          <Button
                            className="button-temp button-container set-price-button-element "
                            id="btn-confirm-custom"
                            onClick={() => {
                              handleConfirmPrice("userB");
                              setShowPaymentOptions(true);
                              setShowConfirmPaymentButton(false);
                              setShowConfirmPayment(false);
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </ReactBSAlert>
              </div>
            </>
          )}

        {showConfirmPaymentButton &&
          userRole === "userB" &&
          userBUploadState !== SwapState.DENIED_SWAP &&
          userAUploadState !== SwapState.DENIED_SWAP &&
          userBUploadState !== SwapState.PAYMENT_FAILURE &&
          userAUploadState !== SwapState.PAYMENT_FAILURE && (
            <div className="swappage-invite">
              <Button
                id="set-price-button"
                onClick={handleSetShowConfirmPayment}
              >
                Confirm Payment
              </Button>
            </div>
          )}

        {showPaymentOptions &&
          userRole === "userB" &&
          swapData.IS_SWAP_CLOSED == false &&
          swapData.SWAP_USER_B_INFO.SWAP_STATE !== SwapState.DENIED_SWAP &&
          swapData.SWAP_USER_A_INFO.SWAP_STATE !== SwapState.DENIED_SWAP && (
            <>
              <div className="pay-card-wrapper">
                <ReactBSAlert
                  title=""
                  type="controlled"
                  customClass="set-price-alert"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Confirm Payment"
                  onConfirm={() => {
                    setShowPaymentOptions(false);
                    setShowPaymentOptionsButton(true);
                    setShowConfirmPayment(false);
                  }}
                  onCancel={() => {
                    setShowPaymentOptions(false);
                    setShowPaymentOptionsButton(true);

                    setShowConfirmPayment(false);
                  }}
                  confirmBtnBsStyle="info"
                  confirmBtnText="Close"
                >
                  <>
                    <div className="pay-card-wrapper">
                      <PaymentOptions
                        paypal={swapData.SWAP_PAYPAL}
                        onPaymentSuccess={handlePaymentSuccess}
                        onPaymentCancel={handlePaymentCancel}
                        setProcessingPayment={setIsProcessingPayment}
                      />
                    </div>
                  </>
                </ReactBSAlert>
              </div>
            </>
          )}

        {showPaymentOptionsButton &&
          userRole === "userB" &&
          userBUploadState !== SwapState.DENIED_SWAP &&
          userAUploadState !== SwapState.DENIED_SWAP &&
          userBUploadState !== SwapState.PAYMENT_FAILURE &&
          userAUploadState !== SwapState.PAYMENT_FAILURE && (
            <div className="swappage-invite">
              {isProcessingPayment ? (
                <p className="text-center">Processing payment...</p>
              ) : (
                <Button
                  id="set-price-button"
                  onClick={handleSetShowPaymentOptions}
                >
                  Pay
                </Button>
              )}
            </div>
          )}
      </div>
      <div>
        <img className="logo-display" alt="uploaded" src={logo} />
      </div>
    </>
  );
}

export default SwapPage;
