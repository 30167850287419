import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/Button.css"; // Import the CSS file

function PaymentOptions({
  paypal,
  onPaymentSuccess,
  onPaymentCancel,
  onClick, // Add onClick to props
  setProcessingPayment, // Add setProcessingPayment prop
}) {
  const paypalRef = useRef(null);
  const [isSdkReady, setIsSdkReady] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_ENVIRONMENT === "sandbox"
    ? "http://localhost:3001/api"
    : `${process.env.REACT_APP_API_BASE_URL}/api`; // Use correct API base URL


  const url_to_head = (url) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject("Error loading script.");
      document.head.appendChild(script);
    });
  };

  const loadPayPalSDK = (clientID, currency, intent, elementId) => {
    const paypal_sdk_url = `https://www.paypal.com/sdk/js?client-id=${clientID}&currency=${currency}&intent=${intent}&components=buttons&disable-funding=credit,card,eps`;

    return url_to_head(paypal_sdk_url)
      .then(() => {
        if (window.paypal) {
          const element = document.getElementById(elementId);
          if (element) {
            window.paypal
              .Buttons({
                onClick: (data) => {
                  setIsDisabled(true); // Disable button when clicked
                  setProcessingPayment(true); // Notify parent component that payment is processing
                },
                style: {
                  shape: "rect",
                  color: "gold",
                  layout: "vertical",
                  label: "paypal",
                },
                createOrder: async (data, actions) => {
                  const mainAmount = parseFloat(
                    paypal.SWAP_AMOUNT.replace(",", ".")
                  );

                  try {
                    const response = await fetch(
                      `${apiBaseUrl}/create_payment`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          amount: mainAmount,
                          currency: paypal.SWAP_CURRENCY,
                          payeeEmail: paypal.SWAP_PAYEE,
                        }),
                      }
                    );

                    if (!response.ok) {
                      throw new Error("Network response was not ok");
                    }

                    const orderData = await response.json();
                    if (orderData.error) {
                      throw new Error(orderData.error_description);
                    }
                    return orderData.id;
                  } catch (error) {
                    console.error("Error creating order:", error);
                    throw error;
                  }
                },
                onApprove: async (data, actions) => {
                  try {
                    const response = await fetch(
                      `${apiBaseUrl}/capture_payment`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ orderId: data.orderID }),
                      }
                    );

                    if (!response.ok) {
                      throw new Error("Network response was not ok");
                    }

                    const details = await response.json();
                    onPaymentSuccess(details);
                  } catch (error) {
                    console.error("Error capturing payment:", error);
                    throw error;
                  }
                },
                onCancel: () => {
                  document.getElementById("alerts").innerHTML = `<div class="ms-alert ms-action2 ms-small"><span class="ms-close"></span><p>Order cancelled!</p></div>`;
                  onPaymentCancel();
                  setProcessingPayment(false); // Reset processing payment state
                },
                onError: (err) => {
                  if (document.getElementById(elementId)) {
                    onPaymentCancel();
                    console.error("PayPal SDK Error:", err);
                  }
                  setProcessingPayment(false); // Reset processing payment state
                },
              })
              .render(`#${elementId}`);
          } else {
            console.error(`Element #${elementId} does not exist`);
          }
        } else {
          console.error("PayPal SDK is not available.");
        }
      })
      .catch((error) => {
        onPaymentCancel();
        console.error("Error loading PayPal SDK:", error);
      });
  };

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT || "sandbox";
    const clientID =
      environment === "sandbox"
        ? process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX
        : process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const currency = paypal.SWAP_CURRENCY;

    const elementId = paypalRef.current.id;

    const loadSdk = async () => {
      await loadPayPalSDK(clientID, currency, "capture", elementId);
      setIsSdkReady(true);
    };

    loadSdk();

    return () => {
      const existingScript = document.querySelector(
        `script[src^="https://www.paypal.com/sdk/js"]`
      );
      if (existingScript) {
        existingScript.remove();
      }
      setIsSdkReady(false);
    };
  }, [paypal.SWAP_CURRENCY]);

  return (
    <div className={isDisabled ? "disabled" : ""} onClick={onClick}>
      <div ref={paypalRef} id="paypal-button-container" />
    </div>
  );
}

export default PaymentOptions;
