import { useEffect } from "react";
import { collection, query, where, getDocs, getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";

//This has the logic to get username out of localStorage

const useUpdateUserDetailsInSwap = (swapData, userRole) => {
  const firestore = getFirestore(); // Initialize Firestore

    const updateUserDetailsInSwap = async (swapData, userRole) => {
      if (!swapData) return;
      let swapId = swapData.SWAP_ID
      const userToken = localStorage.getItem("userToken"); // Get userToken from localStorage
  
      let user_uid; // Default value if userToken is null
      let user_username; // Default value if userToken is null

      if (userToken) {
        // Fetch user details from USERS collection if userToken is not null
        const usersRef = collection(firestore, "USERS");
        const q = query(usersRef, where("user_uid", "==", userToken));
        const userSnapshot = await getDocs(q);
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          user_uid = userData.user_uid;
          user_username = userData.user_username;
        }
      }

      // Fetch current swap details
      const swapRef = doc(firestore, "SWAPS", swapId);
      const swapDoc = await getDoc(swapRef);

      if (swapDoc.exists()) {
        let currentSwapData = swapDoc.data();
        
        // Check and update user details in swap based on current data state
        if (currentSwapData.SWAP_USER_A_INFO.SWAP_USER_NAME  === "" ) {
          await updateDoc(swapRef, {
            "SWAP_USER_A_INFO.SWAP_USER": user_uid || "userA",
            "SWAP_USER_A_INFO.SWAP_USER_NAME": user_username || "userA"
          });
        } else if (currentSwapData.SWAP_USER_B_INFO.SWAP_USER === "" || currentSwapData.SWAP_USER_B_INFO.SWAP_USER_NAME  ==="" ) {
        
     
          await updateDoc(swapRef, {
            "SWAP_USER_B_INFO.SWAP_USER": user_uid || "userB",
            "SWAP_USER_B_INFO.SWAP_USER_NAME": user_username || "userB"
          });
       /*    console.log(`Updated SWAP_USER_B_INFO for swap ${swapId} with UID: ${user_uid} and username: ${user_username}`);
         */} else {
       /*    console.log(`Both SWAP_USER_A_INFO and SWAP_USER_B_INFO are already filled for swap ${swapId}`);
        */ }

        // Refresh the swap data with current details
        const updatedSwapDoc = await getDoc(swapRef);
        if (updatedSwapDoc.exists()) {
          currentSwapData = updatedSwapDoc.data();
       /*    console.log(`Current swap data for ${swapId}: `, currentSwapData);
        */ }
      } else {
    /*     console.log(`No swap data found for ID: ${swapId}`);
      */ }
    };

    updateUserDetailsInSwap().catch(console.error);
    return updateUserDetailsInSwap;
};

export default useUpdateUserDetailsInSwap;
