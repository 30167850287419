import React from "react";
import "../../assets/css/Card.css";
import "../../assets/css/Button.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SwapMethod } from "model/SwapData";

interface InputCardProps {
  files?: FileList | null | undefined;
  fileImage: JSX.Element;
  defaultText: string;
  onClick?: () => void;
  swapMethod: string;
}

const InputCard: React.FC<InputCardProps> = ({
  onClick,
  files,
  fileImage,
  defaultText,
  swapMethod,
}) => {
  let toolTipText = "Click for Details";

  // Define the tooltip renderer function
  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      className="my-swaps-tooltip-inputCard"
      {...props}
    >
      {toolTipText}
    </Tooltip>
  );

  // Function to render the list of files, if any
  const renderFileList = () => {
    if (files && files.length > 0) {
      return (
        <div className="input-card-content">
          {Array.from(files).map((file, index) => (
            <p style={{ cursor: "default" }} key={index}>
              {file.name}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Conditionally wrap the card with OverlayTrigger based on the conditions
  const cardContent = (
    <div className="card-temp file-input-card" onClick={onClick}>
      {fileImage}
      {renderFileList()}
      {defaultText && <div className="download-file-text">{defaultText}</div>}
    </div>
  );

  return defaultText && swapMethod === SwapMethod.FILE ? (
    <OverlayTrigger
      placement="top"
      delay={{ show: 150, hide: 100 }}
      overlay={renderTooltip}
    >
      {cardContent}
    </OverlayTrigger>
  ) : (
    cardContent
  );
};

export default InputCard;
