import { SwapItemState, SwapState } from "model/SwapData.tsx";
import { useEffect, useState } from "react";
import { fetchSwapFilesMetadata } from "utils/getMetaDatafromFile.js";

function FileDetail({
  fileStatusA,
  fileStatusB,
  swapData,
  userAUploadState,
  userBUploadState,
  userRole,
  refreshMetadata,
}) {
  const [metadata, setMetadata] = useState({
    fetchedFileMetadataA: { size: 0, contentType: "N/A", timeCreated: "N/A" },
    fetchedFileMetadataB: { size: 0, contentType: "N/A", timeCreated: "N/A" },
  });

  let userState = userRole === "userA" ? userAUploadState : userBUploadState;
  let fileMetadata =
    userRole === "userA"
      ? metadata.fetchedFileMetadataA
      : metadata.fetchedFileMetadataB;
  let fileStatus = userRole === "userA" ? fileStatusA : fileStatusB;

  function formatDateTime(isoString) {
    if (isoString == "N/A") {
      return "N/A";
    }
    return new Date(isoString).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  function convertSize(sizeBytes) {
    if (sizeBytes === 0) return "0 bytes";
    const sizeName = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(sizeBytes) / Math.log(1024));
    return Math.round(sizeBytes / Math.pow(1024, i)) + " " + sizeName[i];
  }
 
  useEffect(() => {
    if (swapData) {
      if (userRole === "userA") {  // Corrected from '=' to '===' for comparison
       /*  console.log("Fetching metadata for User A");
        */ fetchSwapFilesMetadata(swapData)
          .then((fetchedMetadata) => {
            setMetadata((prevMetadata) => ({
              ...prevMetadata,  // Preserve other metadata state
              fetchedFileMetadataA: fetchedMetadata.fetchedFileMetadataA || prevMetadata.fetchedFileMetadataA,
            }));
          })
          .catch(console.error);
      } else if (userRole === "userB") {  // Corrected to else if for clarity and correct scope
        fetchSwapFilesMetadata(swapData)
          .then((fetchedMetadata) => {
            setMetadata((prevMetadata) => ({
              ...prevMetadata,  // Preserve other metadata state
              fetchedFileMetadataB: fetchedMetadata.fetchedFileMetadataB || prevMetadata.fetchedFileMetadataB,
            }));
          })
          .catch(console.error);
      }
    }
  }, [swapData, refreshMetadata, userRole]);  // Added userRole to dependencies to handle role changes
  
  return (
    <>
      <div className="file-detail-wrapper">
        <div className="file-detail-metadata user-swapState">
          {userState === SwapState.UPLOADED
            ? "Uploaded"
            : userState === SwapState.CONFIRMED_SWAP
            ? "Swap confirmed"
            : userState === SwapState.DENIED_SWAP
            ? "Swap denied"
            : userState === SwapState.PAYMENT_PRICE_PENDING
            ? "waiting for price"
            : userState === SwapState.PAYMENT_PRICE_DEFINED ||
              userState === SwapState.PAYMENT_PRICE_ACCEPTED
            ? `${swapData.SWAP_PAYPAL.SWAP_AMOUNT} ${swapData.SWAP_PAYPAL.SWAP_CURRENCY}`
            : "Not uploaded"}
        </div>
        <div className="file-detail-wrapper-container">
          <div className="file-detail-metadata">
            Size
            <span>{convertSize(fileMetadata.size)}</span>
          </div>
          <div className="file-detail-metadata">
            Type
            <span>{fileMetadata.contentType}</span>
          </div>
          <div className="file-detail-metadata">
            Uploaded
            <span>{formatDateTime(fileMetadata.timeCreated)}</span>
          </div>
        </div>
        <div className="file-detail-validation">
          Virus Check
          <span
            id={
              fileStatus === SwapItemState.SAFE
                ? "item-style-safe"
                : fileStatus === SwapItemState.NOT_SAFE
                ? "item-style-not-safe"
                : "item-style-checking"
            }
          >
            {fileStatus === SwapItemState.SAFE ||
            fileStatus === SwapItemState.NOT_SAFE
              ? " " + fileStatus
              : " checking..."}
          </span>
        </div>
      </div>
    </>
  );
}

export default FileDetail;
