import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { FormGroup, Input } from "reactstrap";
import "../../assets/css/Form.css";

interface InputFieldProps {
  value?: string;
  onChange?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Corrected type
}

function InputField({ value, onKeyDown, onChange }: InputFieldProps) {
  const [inputValue, setInputValue] = useState<string>(value || "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Allow only numeric input
    if (/^\d*$/.test(newValue)) {
      // Update the local state
      setInputValue(newValue);

      // Call the onChange prop if provided
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  const handleRightClick = async (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevent the default context menu

    try {
      const clipboardText = await navigator.clipboard.readText();
      // Check if clipboard text is numeric
      if (/^\d*$/.test(clipboardText)) {
        setInputValue(clipboardText);

        // Call the onChange prop if provided
        if (onChange) {
          onChange(clipboardText);
        }
      }
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };

  const inputClass =
    inputValue.length === 5
      ? "has-success"
      : inputValue.length > 0
      ? "has-danger"
      : "";
  const inputTextClass =
    inputValue.length === 5
      ? "input-field-text-black"
      : "input-field-text-gray";

  return (
    <div className="input-field">
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormGroup className={inputClass}>
            <Input
              className={`form-control-button ${inputTextClass}`}
              type="text"
              maxLength={5}
              pattern="\d{1,5}"
              placeholder="00000"
              value={inputValue}
              onChange={handleChange}
              onContextMenu={handleRightClick}
              onKeyDown={onKeyDown}
            />
          </FormGroup>
        </Form.Group>
      </Form>
    </div>
  );
}

export default InputField;
