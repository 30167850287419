import React, { useEffect } from "react";
import JoinSwapCard from "../../components/SwapCards/JoinSwapCard/JoinSwapCard";
import CreateSwapCard from "../../components/SwapCards/CreateSwapCard/CreateSwapCard";
import "../../assets/css/Homepage.css";
import { SwapData } from "../../model/SwapData";
import Toastalert from "../../components/ToastAlert/ToastAlert";

interface HomepageProps {
  handleJoinTradeClick: (swapCode: string) => Promise<string>;
  handleCreateTradeClick: () => Promise<string>;
  swapData: SwapData | null;
}

const Homepage: React.FC<HomepageProps> = ({
  handleJoinTradeClick,
  handleCreateTradeClick,
  swapData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setViewportUnits = () => {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };

  useEffect(() => {
    setViewportUnits();
    window.addEventListener('resize', setViewportUnits);
    return () => window.removeEventListener('resize', setViewportUnits);
  }, []);
  
  return (
    <>
      <div className="homepage">
        <JoinSwapCard
          swapData={swapData}
          handleJoinTradeClick={handleJoinTradeClick}
        />
        <CreateSwapCard
          swapData={swapData}
          handleCreateTradeClick={handleCreateTradeClick}
        />
      </div>
    </>
  );
};

export default Homepage;
