/*!

=========================================================
* Black Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import { useAuth } from "./layouts/AuthContext.js"; // Adjust the path as needed
import 'bootstrap/dist/css/bootstrap.min.css'; 
import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import { FirebaseProvider } from "routes/FireBaseSetup/fireBaseSetup";
import { LayoutTypes } from "model/LayoutTypes.tsx";
import { AuthProvider } from "layouts/AuthContext.js";


const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <FirebaseProvider>
      <BrowserRouter>
        <Routes>
          <Route path={`${LayoutTypes.layout1}/*`} element={<AuthLayout />} />
          <Route
            path={`${LayoutTypes.layout2}/*`}
            element={
              isAuthenticated ? (
                <AdminLayout />
              ) : (
                <Navigate
                  to={`../${LayoutTypes.layout1}/register-login`}
                  replace
                />
              )
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={
                  isAuthenticated
                    ? `../${LayoutTypes.layout2}/swap`
                    : `..${LayoutTypes.layout1}/swap`
                }
                replace
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </FirebaseProvider>
  );
};

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,

document.getElementById("root")
);
