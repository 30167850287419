import React, { useRef, useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import "../../../assets/css/Button.css";
import NotificationAlert from "react-notification-alert";
import "../../../assets/css/Button.css";

interface HelpButtonProps {
  info: "joinSwapHelp" | "createSwapHelp";
}

const HelpButton: React.FC<HelpButtonProps> = ({ info }) => {
  const notificationAlertRef = useRef<NotificationAlert>(null);
  const [modalNotice, setModalNotice] = useState(false);
  const showJoinHelp = info === "joinSwapHelp";
  const showCreateHelp = info === "createSwapHelp";

  const toggleModalNotice = (e: any) => {
    setModalNotice(!modalNotice);
    const buttonsCollection = document.getElementsByClassName("help-button");

    var buttons = Array.from(buttonsCollection);

    buttons.forEach((button) => {
      (button as HTMLElement).blur();
    });
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <Button
        id="help-button-one"
        variant="info"
        className="button-temp help-button"
        onClick={toggleModalNotice}
      >
        +help
      </Button>
      {showJoinHelp && (
        <Modal isOpen={modalNotice} toggle={toggleModalNotice}>
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalNotice}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h5 className="modal-title" id="myModalLabel">
              How do I join a swap?
            </h5>
          </div>
          <ModalBody className="text-center">
            <div className="instruction">
              <Row>
                <Col md="12">
                  <strong>Join Swap</strong>
                  <p className="description">
                    Login or register through
                    <a href="https://swappd.io/guest/login/"> Swappd Login</a>.
                    <br />
                    Once you have an account, your swappd files will be saved
                    there, allowing you to download them anytime. As a member,
                    you'll also enjoy additional features, but we'll let you
                    discover those on your own.
                  </p>
                </Col>
                {/* <Col md="4">
        <div>
          <img
            alt="..."
            className="rounded img-raised"
            src={require("assets/img/bg1.jpg")}
          /> 
        </div>
      </Col> */}
              </Row>
            </div>
            <div className="instruction">
              <Row>
                <Col md="12">
                  <br />
                  <strong>Use Your SwapCode</strong>
                  <p className="description">
                    Enter your 5-digit SwapCode in the provided field and click
                    "Join".
                    <br />
                  </p>
                </Col>
              </Row>
            </div>
            <p>
              <br />
              If you have any further questions, please don't hesitate to
              contact us. We're here to assist you!
            </p>
          </ModalBody>

          <div className="modal-footer justify-content-center">
            <Button
              className="btn-round"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalNotice}
            >
              Sounds good!
            </Button>
          </div>
        </Modal>
      )}
      {showCreateHelp && (
        <Modal isOpen={modalNotice} toggle={toggleModalNotice}>
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalNotice}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h5 className="modal-title" id="myModalLabel">
              How do I create a swap?
            </h5>
          </div>
          <ModalBody className="text-center">
            <div className="instruction">
              <Row>
                <Col md="12">
                  <strong>Create a Swap</strong>
                  <p className="description">
                    Log in or register through
                    <a href="https://swappd.io/guest/login/"> Swappd Login</a>.
                    <br />
                    Once logged in, you can create swaps by exchanging files or
                    requesting a payment via PayPal. Your transactions are
                    secure and scanned for viruses. You can also view
                    information about the other party involved in the swap.
                  </p>
                </Col>
              </Row>
            </div>
            <div className="instruction">
              <Row>
                <Col md="12">
                  <br />
                  <strong>Premium Features</strong>
                  <p className="description">
                    Upgrade to premium for the best overview and analytics of
                    your swaps. With premium, you can have multiple swaps open
                    simultaneously and enjoy 1-to-many swaps, where your item
                    remains on sale until you close the swap. Otherwise, swaps
                    are typically 1-to-1.
                    <br />
                  </p>
                </Col>
              </Row>
            </div>
            <p>
              <br />
              If you have any further questions, please don't hesitate to
              contact us. We're here to assist you!
            </p>
          </ModalBody>

          <div className="modal-footer justify-content-center">
            <Button
              className="btn-round"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalNotice}
            >
              Sounds good!
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HelpButton;
