import React, { useState } from "react";
import Card from "../../Card/Card";
import HelpButton from "../../../components/Buttons/HelpButton/HelpButton";
import ForwardButton from "../../../components/Buttons/ForwardButton/ForwardButton";
import "../../../assets/css/SwapCard.css";
import SwapCardTitle from "../SwapCardTitle";
import SwapCardDescription from "../SwapCardDescription";
import InputField from "../../../components/InputField/InputField";
import { SwapData } from "../../../model/SwapData";

interface JoinSwapCardProps {
  handleJoinTradeClick: (inputValue: string) => Promise<string>;
  swapData: SwapData | null;
}

const JoinSwapCard: React.FC<JoinSwapCardProps> = ({
  handleJoinTradeClick,
  swapData,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleChange = (newValue: string) => {
    // Update the local state
    setInputValue(newValue);
  };

  const handleClick = async () => {
    // Pass the input value to the parent handler
    await handleJoinTradeClick(inputValue);
    return handleJoinTradeClick(inputValue);
  };



  const handleKeyDown = (e:any) => {
    if (
      e.key === "Enter"
    ) {
      e.preventDefault(); // Prevent the default action
      handleClick()
    }
  };

  return (
    <Card className="swap-card">
      <div className="card-title-container">
        <SwapCardTitle title1="join" title2="Swap" />
      </div>
      <div className="card-content-container">
        <SwapCardDescription
          swapCardClass="join-swap"
          description="enter swap code"
        >
          <InputField
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </SwapCardDescription>
      </div>
      <HelpButton info="joinSwapHelp" />
      <ForwardButton value={inputValue} onClick={handleClick}>
        join
      </ForwardButton>
    </Card>
  );
};

export default JoinSwapCard;
