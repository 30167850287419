import React, { ReactNode } from 'react';


interface SwapCardDescriptionProps {
  description: string;
  children: ReactNode;
  swapCardClass: string;
}

const SwapCardDescription: React.FC<SwapCardDescriptionProps> = ({ description, children, swapCardClass}) => {
  return (
    <div className={`swapCard-description-wrapper ${swapCardClass}`}>
      <p className='swapCard-description'>{description}</p>
      <div className='swapCard-element'>{children}</div>
    </div>
  );
}

export default SwapCardDescription;
