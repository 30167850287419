import React, { useEffect } from "react";
import classnames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import "../../assets/css/Homepage.css";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import { LayoutTypes } from "model/LayoutTypes.tsx";

const AuthNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
    setColor(collapseOpen ? "navbar-transparent" : "bg-white");
  };

  // Define the links for the navigation bar
  const navLinks = [
    { path: "/swap", label: "Swap", icon: "icon-chart-bar-32" },
    { path: "/go-to-dashboard", label: "Dashboard", icon: "icon-chart-bar-32" },
    { path: "/pricing", label: "Pricing", icon: "icon-coins" },
    { path: "/register", label: "Register", icon: "icon-laptop" },
    { path: "/login", label: "Login", icon: "icon-single-02" },

    /*   { path: "/lock-screen", label: "Lock", icon: "icon-lock-circle" } */
  ];

  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", color)}
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
            {props.brandText}
          </NavbarBrand>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse isOpen={collapseOpen} navbar>
          <Nav className="ml-auto" navbar>
            {navLinks.map((link, index) => (
              <NavItem key={index}>
                <NavLink
                  to={`${LayoutTypes.layout1}${link.path}`}
                  onClick={() => {
                    setCollapseOpen(false);
                    setColor("navbar-transparent");
                  }}
                  className={`nav-link ${
                    location.pathname === `${LayoutTypes.layout1}${link.path}`
                      ? "text-primary"
                      : ""
                  }`}
                >
                  <i className={`tim-icons ${link.icon}`} /> {link.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default AuthNavbar;
