import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";


// This is just used for name cards, info

const useFetchSwapUsernames = (swapData,userRole) => {

  const [usernames, setUsernames] = useState({ usernameA: '', usernameB: '' });
  const firestore = getFirestore(); // Initialize Firestore
  let swapId = swapData.SWAP_ID

  useEffect(() => {
    const getSwapUsernames = async () => {
      if (!swapData || !swapData.SWAP_ID) {
     /*    console.log("swapData is not set or SWAP_ID is missing.");
      */   return;
      }
      const swapRef = doc(firestore, "SWAPS", swapId);
      const swapSnapshot = await getDoc(swapRef);

      if (swapSnapshot.exists()) { 
        const swapData = swapSnapshot.data();
        const usernameA = swapData.SWAP_USER_A_INFO?.SWAP_USER_NAME || '';
        const usernameB = swapData.SWAP_USER_B_INFO?.SWAP_USER_NAME || '';
        setUsernames({ usernameA, usernameB });
   /*      console.log(`Fetched usernames: ${usernameA} and ${usernameB} for swap ${swapId}`);
    */   } else {
     /*    console.log(`No swap found with ID ${swapId}`);
     */  }
    };

    getSwapUsernames().catch(console.error);
  }, [swapData.SWAP_ID,swapData.SWAP_USER_A_INFO?.SWAP_USER_NAME, swapData.SWAP_USER_B_INFO?.SWAP_USER_NAME, userRole]); // Re-run this effect if swapId changes

  return usernames;
};

export default useFetchSwapUsernames;
