import { doc, getDoc, setDoc, getFirestore, getDocFromServer } from "firebase/firestore";
import { SwapItemState } from "model/SwapData.tsx";

/**
 * Fetches the latest swap data and updates specified fields dynamically.
 *
 * @param swapId The ID of the swap data to fetch and update.
 * @param updates An object containing the fields to update.
 */
export const updateAndUploadSwapData = async (swapId, updates = {}) => {
  const db = getFirestore();
  const swapRef = doc(db, "SWAPS", swapId);

  try {
    const docSnap = await getDoc(swapRef);
    if (!docSnap.exists()) {
     /*  console.error("No swap data found for ID:", swapId);
      */ return;
    }

    const latestSwapData = docSnap.data();

    // If additional fields are provided, apply them directly to the document
    const updatePayload = {
      ...latestSwapData,
      ...updates.additionalFields // Apply additional fields directly to the root or appropriate level
    };
  
    await setDoc(swapRef, updatePayload, { merge: true });

  /*   console.log("Swap data updated successfully:", updatePayload);
   */} catch (error) {
 /*    console.error("Error updating swap data:", error);
  */ }
};
