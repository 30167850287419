import React, { useEffect, useState, useRef } from "react";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
} from "firebase/firestore";
import "../../assets/css/Timeline.css";
import "../../assets/css/FileDetail.css";
import "../../assets/css/SwapPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Badge, Card, CardBody, Row, Col } from "reactstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import { SwapState, SwapMethod } from "model/SwapData";
import DownloadButton, {
  handleDownload,
} from "components/Buttons/DownloadButton/DownloadButton.tsx";
import SwapPage from "./Swappage.js";
import fileUserA from "assets/img/file_userA.png";
import fileUserB from "assets/img/file_userB.png";
import paymentUserA from "assets/img/payment_userA.png";
import paymentUserB from "assets/img/payment_userB.png";
import swapArrows from "assets/img/swap_arrows.png";

const getSwapMethodPicture = (swapMethod, user) => {
  if (user === "userA") {
    if (swapMethod === SwapMethod.FILE) {
      return fileUserA;
    } else if (swapMethod === SwapMethod.PAYMENT) {
      return paymentUserA;
    }
  } else if (user === "userB") {
    if (swapMethod === SwapMethod.FILE) {
      return fileUserB;
    } else if (swapMethod === SwapMethod.PAYMENT) {
      return paymentUserB;
    }
  }
  return "";
};

const MySwaps = () => {
  const [swaps, setSwaps] = useState([]);
  const navigate = useNavigate();
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const listInnerRef = useRef();
  const [modalNotice, setModalNotice] = useState(false);
  const [currentSwap, setCurrentSwap] = useState(null);
  const [toggleCardMobile, setToggleCardMobile] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.body.classList.add("myswaps");
    return () => {
      document.body.classList.remove("myswaps");
    };
  }, []);

  const renderTooltip = (text) =>
    text !== "" ? (
      <Tooltip id="button-tooltip" className="my-swaps-tooltip-detail">
        {text}
      </Tooltip>
    ) : null;

  const fetchSwapDetails = async (SWAP_ID) => {
    /*  console.log("Fetching swap with ID:", SWAP_ID);
     */ const db = getFirestore();

    const swapsCollection = collection(db, "SWAPS");
    const q = query(swapsCollection, where("SWAP_ID", "==", SWAP_ID));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const swapDoc = querySnapshot.docs[0];
      return swapDoc.data();
    } else {
      console.error("Swap not found with ID:", SWAP_ID);
      return null;
    }
  };

  const rejoinSwap = async (SWAP_ID) => {
    const swapData = await fetchSwapDetails(SWAP_ID);
    if (!swapData) return;
    navigate(`../swaps/${swapData.SWAP_CODE}`, { state: { swapData } });
  };

  const fetchSwaps = async (lastVisibleDoc) => {
    setIsFetching(true);
    const swapsRef = collection(db, "SWAPS");
    let q = query(swapsRef, orderBy("SWAP_DATE", "desc"), limit(4));

    if (lastVisibleDoc) {
      q = query(
        swapsRef,
        orderBy("SWAP_DATE", "desc"),
        startAfter(lastVisibleDoc),
        limit(4)
      );
    }

    const documentSnapshots = await getDocs(q);
    const newSwaps = [];
    const userToken = localStorage.getItem("userToken");

    documentSnapshots.forEach((doc) => {
      const data = doc.data();
      if (
        data.SWAP_USER_A_INFO.SWAP_USER === userToken ||
        data.SWAP_USER_B_INFO.SWAP_USER === userToken
      ) {
        const swap = {
          ...data,
          id: doc.id,
          isInverted: data.SWAP_USER_B_INFO.SWAP_USER === userToken,
        };
        newSwaps.push(swap);
      }
    });

    setSwaps((prevSwaps) => {
      const mergedSwaps = [...prevSwaps, ...newSwaps];
      const uniqueSwaps = Array.from(
        new Set(mergedSwaps.map((swap) => swap.id))
      ).map((id) => mergedSwaps.find((swap) => swap.id === id));
      return uniqueSwaps;
    });
    setIsLoading(false);
    setIsFetching(false);
    if (documentSnapshots.docs.length > 0) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    }
  };

  useEffect(() => {
    fetchSwaps();
  }, []);

  const handleScroll = () => {
    if (listInnerRef.current && !isFetching) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 7) {
        fetchSwaps(lastVisible);
      }
    }
  };

  const toggleCardViewHandler = () => {
    if (window.innerWidth <= 600) {
      setToggleCardMobile((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const element = listInnerRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const renderSwapItem = (swap) => {
    const swapStatusClosedConfirmed =
      swap.IS_SWAP_CLOSED &&
      swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP &&
      (swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP ||
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.PAYMENT_SUCCESS);
    const swapStatusClosedDenied =
      (swap.IS_SWAP_CLOSED &&
        swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.DENIED_SWAP &&
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP) ||
      (swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.UPLOADED &&
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.DENIED_SWAP) ||
      (swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP &&
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.DENIED_SWAP) ||
      (swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP &&
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.PAYMENT_PRICE_DENIED);

    const swapStatus =
      swapStatusClosedConfirmed || swapStatusClosedDenied ? "Closed" : "Open";

    let toolTipText = "Click for Details";

    const swapDate = new Date(swap.SWAP_DATE).toLocaleString();

    const swapIsClosed =
      swap.IS_SWAP_CLOSED ||
      (swap.SWAP_USER_A_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP &&
        swap.SWAP_USER_B_INFO.SWAP_STATE === SwapState.CONFIRMED_SWAP)
        ? true
        : false;

    return (
      <li
        className={`${swap.isInverted ? "timeline-inverted" : ""}`}
        key={swap.id}
      >
        <div
          className={`timeline-badge ${
            swap.isInverted ? "joined-swap-circle" : "created-swap-circle"
          }`}
        >
          <i
            className={`tim-icons my-swaps-icon ${
              swap.isInverted
                ? "icon-cloud-download-93"
                : "icon-cloud-upload-94"
            }`}
          />
        </div>
        <OverlayTrigger
          placement="top"
          delay={{ show: 50, hide: 0 }}
          overlay={renderTooltip(toolTipText)}
        >
          <div
            className="timeline-panel"
            onClick={() => {
              setCurrentSwap(swap);
              setModalNotice(true);
            }}
          >
            <div className="timeline-heading">
              <Badge
                className={`${
                  swap.isInverted ? "joined-swap-badge" : "created-swap-badge"
                }`}
                pill
              >
                {swap.id}
              </Badge>
              <Badge
                className={`${
                  swapStatus === "Closed" && swapStatusClosedConfirmed
                    ? "status-closed-confirmed"
                    : swapStatus === "Closed" && swapStatusClosedDenied
                    ? "status-closed-denied"
                    : "status-pending"
                }`}
                pill
              >
                {swapStatus}
              </Badge>
            </div>
            <div className="timeline-body">
              <h6>
                <i className="ti-time" /> {swapDate}
              </h6>
            </div>
          </div>
        </OverlayTrigger>
      </li>
    );
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100svh",
        }}
      >
        Loading...
      </div>
    );
  } else {
    return (
      <div
        className="content"
        ref={listInnerRef}
        onScroll={handleScroll}
        style={{ height: "20vh", overflow: "auto" }}
      >
        <Row>
          <Col md="12">
            <Card className="card-timeline card-plain">
              <CardBody>
                {swaps.length > 0 ? (
                  <ul className="timeline">
                    {swaps.map((swap) => renderSwapItem(swap))}
                  </ul>
                ) : (
                  <div className="no-swaps-found">No Swaps found</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {modalNotice && currentSwap && (
          <ReactBSAlert
            title=""
            customClass="swap-detail-temp"
            style={{ display: "block", marginTop: "100px" }}
            onConfirm={() => {
              setModalNotice(false);
              setCurrentSwap(null);
              setToggleCardMobile(false);
            }}
            onCancel={() => {
              setModalNotice(false);
              setCurrentSwap(null);
              setToggleCardMobile(false);
            }}
            confirmBtnBsStyle="info"
            confirmBtnText="Close"
            showCancel={false}
          >
            <div className="card-temp file-detail-wrapper myswaps">
              {toggleCardMobile ? (
                <div
                  className="file-detail-info userCard"
                  id={toggleCardMobile ? "" : "hideOnMobile"}
                >
                  {currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME ? (
                    <div
                      className="user-name-card-sm"
                      onClick={toggleCardViewHandler}
                    >
                      <img
                        className="file-image-arrow myswaps"
                        alt="uploaded"
                        src={swapArrows}
                      />
                      {currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME}
                    </div>
                  ) : (
                    ""
                    /* <div
                      className="user-name-card-sm"
                      onClick={toggleCardViewHandler}
                    >
                      unknown
                    </div> */
                  )}
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        className="my-swaps-tooltip"
                        id={`tooltip-${currentSwap.id}-A`}
                      >
                        {currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.DENIED_SWAP
                          ? "Denied"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.CONFIRMED_SWAP
                          ? "Confirmed"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_ACCEPTED
                          ? "Price accepted"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_DENIED
                          ? "Price denied"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_DEFINED
                          ? "Price defined"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_SUCCESS
                          ? "Paid"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_FAILURE
                          ? "Not Paid"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.UPLOADED
                          ? "Uploaded"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.JOINED
                          ? "Joined"
                          : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.NOT_JOINED
                          ? "Not joined"
                          : "Status Unknown"}
                      </Tooltip>
                    }
                  >
                    <div className="file-image myswaps">
                      {getSwapMethodPicture(
                        currentSwap.SWAP_USER_B_INFO.SWAP_METHOD,
                        "userB"
                      ) !== "" ? (
                        <img
                          alt="Uploaded"
                          src={getSwapMethodPicture(
                            currentSwap.SWAP_USER_B_INFO.SWAP_METHOD,
                            "userB"
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </OverlayTrigger>

                  {currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                    SwapState.CONFIRMED_SWAP &&
                  currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                    SwapState.CONFIRMED_SWAP ? (
                    <div
                      className="user-file-card-sm myswaps"
                      onClick={() =>
                        handleDownload(
                          currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                            .SWAP_ITEM_LINK,
                          currentSwap.SWAP_CODE
                        )
                      }
                    >
                      {
                        currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                          .SWAP_ITEM_NAME
                      }
                    </div>
                  ) : currentSwap.SWAP_USER_A_INFO.SWAP_METHOD ===
                      SwapMethod.PAYMENT ||
                    (currentSwap.SWAP_USER_B_INFO.SWAP_METHOD ===
                      SwapMethod.PAYMENT &&
                      currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP &&
                      (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP ||
                        currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_SUCCESS)) ? (
                    <div className="user-file-card-sm readonly myswaps">
                      {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                      {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                    </div>
                  ) : currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                      .SWAP_ITEM_NAME ? (
                    <div className="user-file-card-sm readonly myswaps">
                      {
                        currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                          .SWAP_ITEM_NAME
                      }
                    </div>
                  ) : (
                    currentSwap.SWAP_PAYPAL.SWAP_AMOUNT !== "" &&
                    currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME !== "" && (
                      <div className="user-file-card-sm readonly myswaps">
                        {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                        {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div
                  className="file-detail-info userCard"
                  id={!toggleCardMobile ? "" : "hideOnMobile"}
                >
                  {currentSwap.SWAP_USER_A_INFO.SWAP_USER_NAME ? (
                    <div
                      className="user-name-card-sm"
                      onClick={toggleCardViewHandler}
                    >
                      <img
                        className="file-image-arrow myswaps"
                        alt="uploaded"
                        src={swapArrows}
                      />
                      {currentSwap.SWAP_USER_A_INFO.SWAP_USER_NAME}
                    </div>
                  ) : (
                    <div
                      className="user-name-card-sm"
                      onClick={toggleCardViewHandler}
                    >
                      Return
                    </div>
                  )}
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        className="my-swaps-tooltip"
                        id={`tooltip-${currentSwap.id}-A`}
                      >
                        {currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                        SwapState.DENIED_SWAP
                          ? "Denied"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.CONFIRMED_SWAP
                          ? "Confirmed"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_ACCEPTED
                          ? "Price accepted"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_DENIED
                          ? "Price denied"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_DEFINED
                          ? "Price defined"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_SUCCESS
                          ? "Paid"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_FAILURE
                          ? "Not Paid"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.UPLOADED
                          ? "Uploaded"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.JOINED
                          ? "Joined"
                          : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.NOT_JOINED
                          ? "Not joined"
                          : "Status Unknown"}
                      </Tooltip>
                    }
                  >
                    <div className="file-image myswaps">
                      {getSwapMethodPicture(
                        currentSwap.SWAP_USER_A_INFO.SWAP_METHOD,
                        "userA"
                      ) !== "" ? (
                        <img
                          alt="Uploaded"
                          src={getSwapMethodPicture(
                            currentSwap.SWAP_USER_A_INFO.SWAP_METHOD,
                            "userA"
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </OverlayTrigger>

                  {currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                    SwapState.CONFIRMED_SWAP &&
                  (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                    SwapState.CONFIRMED_SWAP ||
                    currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                      SwapState.PAYMENT_SUCCESS) ? (
                    <div
                      className="user-file-card-sm myswaps"
                      onClick={() =>
                        handleDownload(
                          currentSwap.SWAP_USER_A_INFO.SWAP_ITEM_INFO
                            .SWAP_ITEM_LINK,
                          currentSwap.SWAP_CODE
                        )
                      }
                    >
                      {
                        currentSwap.SWAP_USER_A_INFO.SWAP_ITEM_INFO
                          .SWAP_ITEM_NAME
                      }
                    </div>
                  ) : currentSwap.SWAP_USER_A_INFO.SWAP_METHOD ===
                      SwapMethod.PAYMENT ||
                    (currentSwap.SWAP_USER_B_INFO.SWAP_METHOD ===
                      SwapMethod.PAYMENT &&
                      currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP &&
                      (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP ||
                        currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_SUCCESS)) ? (
                    <div className="user-file-card-sm readonly myswaps">
                      {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                      {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                    </div>
                  ) : currentSwap.SWAP_USER_A_INFO.SWAP_ITEM_INFO
                      .SWAP_ITEM_NAME ? (
                    <div className="user-file-card-sm readonly myswaps">
                      {
                        currentSwap.SWAP_USER_A_INFO.SWAP_ITEM_INFO
                          .SWAP_ITEM_NAME
                      }
                    </div>
                  ) : (
                    currentSwap.SWAP_PAYPAL.SWAP_AMOUNT !== "" &&
                    currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME !== "" && (
                      <div className="user-file-card-sm readonly myswaps">
                        {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                        {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                      </div>
                    )
                  )}
                </div>
              )}

              <div className="file-detail-info-wrapper myswaps">
                <div>
                  Status:&nbsp;
                  <span
                    id={`${
                      currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP &&
                      (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP ||
                        currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_SUCCESS)
                        ? "status-closed-confirmed"
                        : currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                            SwapState.DENIED_SWAP ||
                          currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.DENIED_SWAP ||
                          currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_FAILURE ||
                          currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                            SwapState.PAYMENT_PRICE_DENIED
                        ? "status-closed-denied"
                        : "status-pending"
                    }`}
                  >
                    {currentSwap.IS_SWAP_CLOSED ||
                    (currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                      SwapState.CONFIRMED_SWAP &&
                      (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.CONFIRMED_SWAP ||
                        currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_SUCCESS))
                      ? "Closed"
                      : "Open"}
                  </span>
                </div>

                <div className="file-detail-info">ID: {currentSwap.id}</div>

                <div className="file-detail-info">
                  Date: {new Date(currentSwap.SWAP_DATE).toLocaleString()}
                </div>
              </div>

              <div className="file-detail-info" id="userB-file-info">
                {currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME ? (
                  <div
                    className="user-name-card-sm"
                    onClick={toggleCardViewHandler}
                  >
                    <img
                      className="file-image-arrow myswaps"
                      alt="uploaded"
                      src={swapArrows}
                    />
                    {currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME}
                  </div>
                ) : (
                  ""
                  /*  <div
                    className="user-name-card-sm"
                    onClick={toggleCardViewHandler}
                  >
                    unknown
                  </div> */
                )}
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className="my-swaps-tooltip"
                      id={`tooltip-${currentSwap.id}-A`}
                    >
                      {currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                      SwapState.DENIED_SWAP
                        ? "Denied"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.CONFIRMED_SWAP
                        ? "Confirmed"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_PRICE_ACCEPTED
                        ? "Price accepted"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_PRICE_DENIED
                        ? "Price denied"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_PRICE_DEFINED
                        ? "Price defined"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_SUCCESS
                        ? "Paid"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.PAYMENT_FAILURE
                        ? "Not Paid"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.UPLOADED
                        ? "Uploaded"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.JOINED
                        ? "Joined"
                        : currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                          SwapState.NOT_JOINED
                        ? "Not joined"
                        : "Status Unknown"}
                    </Tooltip>
                  }
                >
                  <div className="file-image myswaps">
                    {getSwapMethodPicture(
                      currentSwap.SWAP_USER_B_INFO.SWAP_METHOD,
                      "userB"
                    ) !== "" ? (
                      <img
                        alt="Uploaded"
                        src={getSwapMethodPicture(
                          currentSwap.SWAP_USER_B_INFO.SWAP_METHOD,
                          "userB"
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </OverlayTrigger>

                {currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                  SwapState.CONFIRMED_SWAP &&
                currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                  SwapState.CONFIRMED_SWAP ? (
                  <div
                    className="user-file-card-sm myswaps"
                    onClick={() =>
                      handleDownload(
                        currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                          .SWAP_ITEM_LINK,
                        currentSwap.SWAP_CODE
                      )
                    }
                  >
                    {currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO.SWAP_ITEM_NAME}
                  </div>
                ) : currentSwap.SWAP_USER_A_INFO.SWAP_METHOD ===
                    SwapMethod.PAYMENT ||
                  (currentSwap.SWAP_USER_B_INFO.SWAP_METHOD ===
                    SwapMethod.PAYMENT &&
                    currentSwap.SWAP_USER_A_INFO.SWAP_STATE ===
                      SwapState.CONFIRMED_SWAP &&
                    (currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                      SwapState.CONFIRMED_SWAP ||
                      currentSwap.SWAP_USER_B_INFO.SWAP_STATE ===
                        SwapState.PAYMENT_SUCCESS)) ? (
                  <div className="user-file-card-sm readonly myswaps">
                    {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                    {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                  </div>
                ) : currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO
                    .SWAP_ITEM_NAME ? (
                  <div className="user-file-card-sm readonly myswaps">
                    {currentSwap.SWAP_USER_B_INFO.SWAP_ITEM_INFO.SWAP_ITEM_NAME}
                  </div>
                ) : (
                  currentSwap.SWAP_PAYPAL.SWAP_AMOUNT !== "" &&
                  currentSwap.SWAP_USER_B_INFO.SWAP_USER_NAME !== "" && (
                    <div className="user-file-card-sm readonly myswaps">
                      {currentSwap.SWAP_PAYPAL.SWAP_AMOUNT}{" "}
                      {currentSwap.SWAP_PAYPAL.SWAP_CURRENCY}
                    </div>
                  )
                )}
              </div>
            </div>
          </ReactBSAlert>
        )}

        {swaps.length > 5 ? (
          <p
            style={{ textAlign: "center" }}
            onClick={() => fetchSwaps(lastVisible)}
          >
            More Swaps
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default MySwaps;
